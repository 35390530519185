import React, { useEffect } from "react";
import Homebody from "../Pages/layout/HomeBody";
import Profile from "../Pages/userprofile/Profile";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Buytoken from "../Pages/tokens/BuyToken";
import Blog from "../Pages/blog/Blog";
import NewBlog from "../Pages/blog/NewBlog";
import Result from "../Pages/result/result";
import Blogdetail from "../Pages/blog/BlogDetail";
// import IframeChild from '../components/IFrameBox/child';
import PrivateRoutes from "../Routes/PrivateRoutes";
import AddBlog from "../Pages/blog/NewBlog";

const routes = [
  {
    path: "/blog",
    component: Blog,
    exact: true,
  },
  {
    path: "/blog/addblog",
    component: AddBlog,
    exact: true,
  },
  {
    path: "/blog/:slug",
    component: Blogdetail,
    exact: true,
  },
  // {
  //     path: "/iframe-child",
  //     component: IframeChild,
  //     exact: true
  // },
  {
    path: "/",
    component: Homebody,
    exact: true,
  },
  // {
  //   path: "/profile",
  //   component: Profile,
  //   exact: true,
  // },
];

const privateRoutesContant = [
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/signin",
    exact: true,
  },
  {
    path: "/token",
    component: Buytoken,
    exact: true,
  },
  {
    path: "/result",
    component: Result,
    exact: true,
  },
  {
    path: "/addblog",
    component: NewBlog,
    exact: true,
  },
];
const AppRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);
  return (
    <Routes>
      {privateRoutesContant.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={true}
            element={
              <PrivateRoutes>
                <route.component />
              </PrivateRoutes>
            }
          />
        );
      })}
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={true}
            element={<route.component />}
          />
        );
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
