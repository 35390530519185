export const START_GAME = "START_GAME";
export const PLAYING_GAME = "PLAYING_GAME";
export const STOP_GAME = "STOP_GAME";


export const startGame = () => {
    return {
        type: START_GAME
    }
}

export const playingGame = () => {
    return {
        type: PLAYING_GAME
    }
}

export const stopGame = () => {
    return {
        type: STOP_GAME
    }
}