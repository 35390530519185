import React, { useState, useEffect, useRef } from "react";
import Iframe from "./Iframe";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { CDN_IMAGE_URL } from "../../utils/GlobalConfig";
import { stopGame } from "../../Redux/actions/gameAction";
import {
  showLoader,
  setActiveGame,
  setGameType,
} from "../../Redux/actions/homeAction";
import { services } from "../../utils/services";
import { getToken, updateUserDetail } from "../../Redux/actions/authAction";
import {
  checkAllowance,
  gameContract,
  signAndData,
  submitScoreContract,
} from "../../utils/web3Function";
import { ethers } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";

function IFrameBox() {
  const provider = useWeb3ModalProvider();
  const [link, setlink] = useState("");
  const [showGame, setShowGame] = useState(false);
  const [feeId, setFeeId] = useState();
  const activeGame = useSelector((state) => get(state, "home.activeGame", {}));
  const playGame = useSelector((state) => get(state, "game.playGame", {}));
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );
  const selectedGameType = useSelector((state) =>
    get(state, "home.gameType", {})
  );

  const dispatch = useDispatch();
  const iframRef = useRef();

  //   useEffect(() => {
  //     if (playGame?.start) {
  //       dispatch(showLoader(true));
  //       checkAllowance(ethers.utils.parseUnits("10.0", 18));
  //       doStartPlay();
  //     }
  //   }, [playGame]);

  useEffect(() => {
    console.log("line-50");
    const startPlay = async () => {
      const teirrId = get(selectedGameType, "tierPrice", "");
      if (playGame?.start) {
        dispatch(showLoader(true));
        if (teirrId > 0) {
          // await checkAllowance(ethers.utils.parseUnits(teirrId.toString(), 18));
          // await checkAllowance((ethers.utils.parseUnits(5 * 10 ** 9, 18)).toString());
          await checkAllowance(
            ethers.utils.parseUnits((5 * 10 ** 9).toString(), 18).toString()
          );

          if (teirrId === 0.3) {
            await gameContract(provider, 11, process.env.REACT_APP_FEEID1);
            setFeeId(0);
          } else if (teirrId === 0.5) {
            await gameContract(provider, 11, process.env.REACT_APP_FEEID2);
            setFeeId(1);
          } else {
            await gameContract(provider, 11, process.env.REACT_APP_FEEID3);
            setFeeId(2);
          }

          doStartPlay();
        } else {
          doStartPlay();
        }
      }
    };

    startPlay();
  }, [playGame]);

  const doStartPlay = async () => {
    // const signature = await signAndData(provider);
    // await submitScoreContract(provider, signature);
    console.log("line-85");
    const _params = {
      userId: get(userDetail, "userId"),
      gameId: get(activeGame, "gameID"),
      tierId: get(selectedGameType, "tierID"),
    };
    const response = await services.doStartPlay(_params);
    const { status, result } = response;
    if (status && get(response, "result.battleId")) {
      const _link = `${CDN_IMAGE_URL}${get(
        activeGame,
        "webGLUrl"
      )}?userID=${get(userDetail, "userId")}&battleId=${get(
        result,
        "battleId"
      )}&token=${getToken()}`;
      setlink(_link);
      setShowGame(true);
      console.log(_link, _params);
      // setBattleId(get(response, 'battleId'));
    } else {
      dispatch(showLoader(false));
    }
  };
  useEffect(() => {
    if (showGame) {
      setTimeout(() => {
        let isActive = true;
        window.addEventListener("message", function (e) {
          if (isActive) {
            onClose();
            isActive = false;
          }
        });
        dispatch(showLoader(false));
      }, 1000);
    }
  }, [showGame]);

  useEffect(() => {
    return () => {
      // window.removeEventListener('message')
    };
  }, []);

  const onClose = () => {
    dispatch(setActiveGame({}));
    dispatch(setGameType({}));
    dispatch(updateUserDetail());
    dispatch(stopGame());
    setlink("");
    setShowGame(false);
  };

  return (
    <>
      {showGame && link ? (
        <div className="game-window">
          <Iframe ref={iframRef} link={link} />
        </div>
      ) : null}
    </>
  );
}

export default IFrameBox;
