import React, { useState, useEffect } from "react";
import profilepic from "../images/avatar.jpg";
import points from "../images/Points.png";
import copy from "../images/copyy.png";
import edit from "../images/edit.png";
import { services } from "../../utils/services"; 
import { useSelector, useDispatch } from "react-redux";
import { get, cloneDeep } from "lodash";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loginUserSuccess,
  updateUserDetail,
} from "../../Redux/actions/authAction";
// import PicUploader from '../../components/PicUploader';
import Cropper from "react-easy-crop";
import "./style.css";
import getCroppedImg from "../../utils/cropImage";
import { showLoader } from "../../Redux/actions/homeAction";
import { MODAL_TYPE, NOTIFICATION_TYPE } from "../../utils/constant";
import { showNotification } from "../../Redux/actions/notificationAction";
import { LIVE_IMAGE_URL } from "../../utils/GlobalConfig";
import { changeModal } from "../../Redux/actions/modalActions";
import fuzukiMobileLogo from "../images/fuzuki-logo-mobile.png";

const Profile = (props) => {
  const [show, setShow] = useState(false);
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );
  const userDetailLoading = useSelector((state) =>
    get(state, "auth.userDetail.loading", {})
  );
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [file, setFile] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useEffect(() => {
    if (userDetailLoading) {
      dispatch(updateUserDetail());
    }
  }, []);

  const handleCopy = () => {
    const textToCopy = get(userDetail, "cirrusAddress", "");
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, "Copid Successfully"));
      },
      // (err) => {
      //   console.error("Failed to copy: ", err);
      // }
    );
  };

  const onChangeImage = (e, name) => {
    const file = e.target.files[0];
    if (file) {
      // setFile(file);
      setPhotoURL(URL.createObjectURL(file));
      setShow(true);
    }
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function() {
    //     let inputData = reader.result;
    //     let replaceValue = inputData.split(",")[0];
    //     let base64String = inputData.replace(replaceValue + ".","");
    //     setFile(base64String);
    // }
  };

  const uploadImage = async (imageBase64) => {
    const param = {
      imageBase64,
    };
    const response = await services.uploadProfileImage(param);
    if (response) {
      const { status, message } = response;
      if (status) {
        const _userDetail = cloneDeep(userDetail);
        _userDetail.image = message;
        dispatch(loginUserSuccess(_userDetail));
        dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, message));
        setPhotoURL("");
        // setFile(null);
        setShow(false);
      } else {
        setPhotoURL("");
        // setFile(null);
        setShow(false);
        dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message));
      }
    }
  };

  const saveImage = async () => {
    dispatch(showLoader(true));
    try {
      const { file, url } = await getCroppedImg(photoURL, croppedAreaPixels);
      if (file && url) {
        let base64String = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let inputData = reader.result;
          let replaceValue = inputData.split(",")[0];
          base64String = inputData.replace(replaceValue + ".", "");
          uploadImage(base64String);
        };
      }
    } catch (error) {}
    dispatch(showLoader(false));
  };

  const onCancelPicture = () => {
    setPhotoURL("");
    // setFile({});
    setShow(false);
  };
  const _imageUrl = get(userDetail, "image", "")
    ? `${LIVE_IMAGE_URL}${get(userDetail, "image")}`
    : profilepic;
  return (
    <div>
      <div className="container">
        {/* <img src={photoURL} /> */}
        <h2 className="profileheading">Profile</h2>
        {/* <PicUploader /> */}
        <div className="profilebox">
          <div className="profile-detail">
            <img className="img-icon avatar" src={_imageUrl} alt="profilepic" />
            <div className="profile-image">
              <div className="avatar-text">
                <span>Upload an image</span>
              </div>
              <input
                className="avatar-uploader"
                name="fieName"
                type="file"
                onChange={(e) => onChangeImage(e, "picture")}
              />
            </div>
            <h2 onClick={() => toast.success("Success Message")}>
              {get(userDetail, "userName")}
            </h2>
            <div className="user-icons">
              {/* <div className="icons">
                                <img className="img-icon" src={points} alt="points" />
                                <span>{get(userDetail, 'totalVersus')}</span>
                            </div> */}
              {/* <div className="icons">
                <img className="img-icon" src={layers} alt="layers" />
                <span>{get(userDetail, "totalCirrus")}</span>
              </div> */}
              <div className="icons">
                <img
                  className="img-icon"
                  src={edit}
                  alt="edit"
                  style={{ height: "30px", cursor: "pointer" }}
                  onClick={() =>
                    dispatch(changeModal(MODAL_TYPE.UPDATEUSERNAME))
                  }
                />
              </div>
            </div>
          </div>
          {/* <p className="logout-btn" onClick={() => dispatch(doLogout())}>
            Sign Out
          </p> */}
        </div>

        <div className="profilebox">
          <div className="progress-detail">
            <h2>Performance Details</h2>
            <progress
              id="userprogress"
              value={get(userDetail, "winningRatio")}
              max="100"
            >
              {" "}
              {get(userDetail, "winningRatio")}{" "}
            </progress>
            <label for="userprogress">
              Battle Level: {get(userDetail, "battleLevel")}
            </label>
          </div>
          <div className="performance-info">
            <div className="game-info">
              <div className="info-group" style={{ marginRight: "20px" }}>
                <span>Total games played: </span>
                <span>{get(userDetail, "totalGamesPlayed")}</span>
              </div>
              <div className="info-group">
                <span>Winning Ratio: </span>
                <span>{get(userDetail, "winningRatio")}%</span>
              </div>
            </div>

            <div className="game-info">
              <div
                className="info-group group-back d-none"
                style={{ marginRight: "30px" }}
              >
                <span>Versus won: </span>
                <img className="img-icon" src={points} alt="points" />
                <span> {get(userDetail, "versusWon")}</span>
              </div>
              <div className="info-group  group-back">
                <span>Fuzuki won: </span>
                <img
                  className="img-icon"
                  src={fuzukiMobileLogo}
                  alt="layers"
                  style={{ height: "45px" }}
                />
                <span>{get(userDetail, "cirrusWon")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="profilebox">
          <div className="account-detail">
            <p>Wallet Details</p>
          </div>
          <p
            className="wallet-address info-group group-back"
            style={{ textAlign: "left" }}
          >
            Public Address : <span> {get(userDetail, "cirrusAddress")} </span>{" "}
            <img
              className="copyicon float-end"
              src={copy}
              alt="copy"
              onClick={handleCopy}
              style={{ cursor: "pointer" }}
            />
          </p>
          <p className="walletinfo-address">
            Add tokens to your Hashbattle account by transferring from Cirrus
            Wallet to above mentioned address
          </p>
          <p className="walletinfo-address">
            (The app is currently on Testnet, hence don’t send real Cirrus
            tokens, else they will be lost forever.)
          </p>
        </div>

        {/* <div className="wallet-page">
          <div className="profilebox">
            <h3>Cashout</h3>
            <div className="token-text">
              <h2>Transfer Cirrus tokens to external wallets.</h2>
              <br />
              <button
                className="btn btn-default buy-btn active"
                onClick={() => dispatch(showModal(MODAL_TYPE.CASHOUT))}
              >
                Cashout
                <span className="up-feature">
                  <img src={lock} alt="padlock" />
                </span>
              </button>
            </div>
          </div>
        </div> */}
      </div>

      {show && (
        <div className="profile-app">
          <div className="container-box">
            <div className="crop-container">
              <Cropper
                image={photoURL}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="controls">
              <div>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range"
                />
              </div>
              <div>
                <button
                  className="btn crop-btn btn-default"
                  onClick={saveImage}
                >
                  Save
                </button>
                <button
                  className="btn crop-btn btn-default"
                  onClick={onCancelPicture}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
