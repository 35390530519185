import "slick-carousel/slick/slick.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useState } from "react";
import Footer from "../Pages/layout/footer";
import Header from "../Pages/layout/header";
import Sidebar from "../Pages/layout/Sidebar";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../Routes";
import Modal from "../components/modals";
import ToastBox from "../components/ToastBox";
import Loader from "../components/Loader";
import IFrameBox from "../components/IFrameBox";
import { useSelector } from "react-redux";
import { get } from "lodash";
function App() {
  const [showSideBar, setShowSideBar] = useState(false);
  const playGame = useSelector((state) => get(state, "game.playGame", {}));

  return (
    <BrowserRouter>
      <div className="hashbattle-container">
        <ToastBox />
        <Modal />
        <Loader />
        <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        {playGame?.start && <IFrameBox />}
        <div className="contentbox">
          <AppRoutes />
        </div>
        <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
