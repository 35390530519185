import { get, isEmpty } from "lodash";
import { MODAL_TYPE, NOTIFICATION_TYPE } from "../../utils/constant";
import { services } from "../../utils/services";
import { changeModal, hideModal } from "./modalActions";
import { getValue } from "../../utils";
import { showLoader } from "./homeAction";
import { showNotification } from "./notificationAction";
import ls from "localstorage-slim";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER_CALL = "LOGOUT_USER_CALL";
export const OTP_UPDATE_ID = "OTP_UPDATE_ID";
export const OTP_UPDATE_OTP = "OTP_UPDATE_OTP";
export const OTP_DETAIL_CLEAR = "OTP_DETAIL_CLEAR";
export const OTP_TYPE = "OTP_TYPE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGINOUT = "USER_LOGINOUT";

export const userLogin = (isLogin) => {
  return {
    type: USER_LOGIN,
    payload: isLogin,
  };
};

export const setData = (key, value) => {
  ls.set(key, value, { ttl: 86400 });
};

export const getData = (key) => {
  if (!ls.get(key)) {
    ls.clear();
    return null;
  }
  return ls.get(key);
};

export const otpUpdateId = (id) => {
  return {
    type: OTP_UPDATE_ID,
    payload: id,
  };
};
export const otpUpdateOtp = (otp) => {
  return {
    type: OTP_UPDATE_OTP,
    payload: otp,
  };
};
export const otpDetailClear = () => {
  return {
    type: OTP_DETAIL_CLEAR,
  };
};
export const setOtpType = (type) => {
  return {
    type: OTP_TYPE,
    payload: type,
  };
};

export const loginUserRequest = (userDetail) => {
  return {
    type: LOGIN_USER_REQUEST,
  };
};

export const loginUserSuccess = (userDetails) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: userDetails,
  };
};

export const loginUserFailure = (error) => {
  return {
    type: LOGIN_USER_FAILURE,
    payload: error,
  };
};
export const logoutUser = () => {
  return {
    type: LOGOUT_USER_CALL,
  };
};

export const setUserDTO = ({ result }) => {
  const _userId = get(result, "userId");
  if (!isEmpty(result)) {
    localStorage.setItem("userId", _userId);
  }
};

export const setUserToken = ({ result, token }) => {
  if (!isEmpty(result) && !isEmpty(token)) {
    localStorage.setItem("token", token);
    // setData("token", token);
  }
};

export const doRegisterUser = (registerForm) => {
  return (dispatch) => {
    services
      .registerUser(registerForm)
      .then((response) => {
        const { status, message } = response;
        if (status) {
          dispatch(changeModal(MODAL_TYPE.LOGIN));
          dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, message));
        } else {
          dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message));
        }
      })
      .catch((e) => {
        // dispatch(showLoader(false))
        // dispatch(loginUserFailure(error))
        dispatch(showNotification(NOTIFICATION_TYPE.ERROR, e));
      });
  };
};
export const doLogin = (userCredentials) => {
  return (dispatch) => {
    dispatch(loginUserRequest());
    dispatch(showLoader(true));
    services
      .loginUser(userCredentials)
      .then((response) => {
        const { status, result, message } = response;
        if (status && result) {
          // debugger
          dispatch(loginUserSuccess(result));
          setUserDTO(response);
          setUserToken(response);
          dispatch(hideModal());
          dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, message));
          dispatch(userLogin(true));
          dispatch(showLoader(false));
        } else {
          dispatch(userLogin(false));
          dispatch(showLoader(false));
          dispatch(loginUserFailure(message));
          dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message));
        }
        dispatch(showLoader(false));
      })
      .catch((error) => {
        dispatch(userLogin(false));
        dispatch(showLoader(false));
        dispatch(loginUserFailure(error));
        dispatch(
          showNotification(NOTIFICATION_TYPE.ERROR, "Something Went Wrong.")
        );
      });
  };
};

export const doLogout = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("token");
  return (dispatch) => {
    dispatch(logoutUser());
    dispatch(userLogin(false));
    dispatch(showLoader(false));
    window.location.href = "/";
  };
};

export const getUserDTO = () => {
  const userDTO = getValue(localStorage.getItem("userId")) || "";
  return userDTO;
};

export const isUserExist = (routesType) => {
  const _userId = getUserDTO();
  if (_userId) {
    return true;
  }
  return false;
};
export const showLoginPopup = () => {
  return (dispatch) => {
    dispatch(changeModal(MODAL_TYPE.LOGIN));
  };
};

export const getToken = () => {
  // const token = getData("token");
  const token = localStorage.getItem("token");
  return token;
};

export const handleUsernameCheckResult = (message) => {
  return {
    type: "HANDLE_USERNAME_CHECK_RESULT",
    payload: { message },
  };
};

// export const checkUserToken = async () => {
//     const userDTO = getUserDTO();
//     const _userId = get(userDTO, 'userId', '');
//     if(_userId) {
//         const userResponse = await services.getuserDetail(_userId);
//         if(!isEmpty(userResponse)) {
//             setUserDTO(userResponse);
//             return true;
//         } else {
//             return false;
//         }
//     }
//     return false;
// }

export const updateUserDetail = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    const _userId = getUserDTO();
    if (_userId) {
      try {
        const response = await services.getuserDetail(_userId);
        if (!isEmpty(response)) {
          setUserDTO(response);
          dispatch(loginUserSuccess(get(response, "result")));
          dispatch(showLoader(false));
          return true;
        } else {
          dispatch(doLogout());
          dispatch(showLoader(false));
          return false;
        }
      } catch (err) {
        dispatch(showLoader(false));
        dispatch(
          showNotification(NOTIFICATION_TYPE.ERROR, "Something Went Wrong.")
        );
        return false;
      }
    } else {
      dispatch(showLoader(false));
    }
  };
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const doUpdateUsername = (userCredentials) => {
  return (dispatch) => {
    dispatch(showLoader(true));
    services
      .updateUsername(userCredentials)
      .then((response) => {
        const { status, result, message } = response;
        if (status && result) {
          // debugger
          dispatch(updateUserDetail());
          dispatch(hideModal());
          dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, message));
          dispatch(showLoader(false));
        } else {
          dispatch(showLoader(false));
          dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message));
        }
        dispatch(showLoader(false));
      })
      .catch((error) => {
        dispatch(showLoader(false));
        dispatch(
          showNotification(NOTIFICATION_TYPE.ERROR, "Something Went Wrong.")
        );
      });
  };
};

export const checkUsername = (userCredentials) => {
  return (dispatch) => {
    services
      .checkUsername(userCredentials)
      .then((response) => {
        const { result, message } = response;
        if (result) {
          // debugger
          dispatch(handleUsernameCheckResult(message));
        } else {
          dispatch(showLoader(false));
          dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message));
        }
        dispatch(showLoader(false));
      })
      .catch((error) => {
        dispatch(showLoader(false));
        dispatch(
          showNotification(NOTIFICATION_TYPE.ERROR, "Something Went Wrong.")
        );
      });
  };
};
