import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showLoginPopup } from '../../Redux/actions/authAction';

const NotFound = () => {
    const  dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/")
        dispatch(showLoginPopup())
    }, []);
    
    return (
        <h1>
            Not Found
        </h1>
    );
}

export default NotFound;
