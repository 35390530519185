import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import menu from "../images/menu.png";
import profilepic from "../images/avatar.jpg";
import blogging from "../images/blogging.png";
import home from "../images/dog-house-2.png";
import setting from "../images/setting.png";
import cart from "../images/shopping-cart.png";
import podium from "../images/podium.png";
import times from "../images/times.png";
import user from "../images/user-2.png";
import layers from "../images/Layers.png";
import points from "../images/Points.png";
import { LIVE_IMAGE_URL } from "../../utils/GlobalConfig";
import fuzukiMobileLogo from "../images/fuzuki-logo-mobile.png";

const Sidebar = (props) => {
  const { setShowSideBar, showSideBar } = props;
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );
  const isUserExist = !isEmpty(userDetail);

  const _imageUrl = get(userDetail, "image", "")
    ? `${LIVE_IMAGE_URL}${get(userDetail, "image")}`
    : profilepic;

  return (
    <div>
      <div className={showSideBar ? "sidebar sidebar-active" : "sidebar"}>
        <ul>
          <li className="menu-btn">
            <a onClick={() => setShowSideBar(false)}>
              <img className="img-icon cancel-btn" src={times} alt="menu" />
            </a>
            <a onClick={() => setShowSideBar(true)}>
              <img className="img-icon bars-btn" src={menu} alt="menu" />
            </a>

            {isUserExist ? (
              <>
                <NavLink to="/" className="">
                  <img
                    className="img-icon avatar"
                    src={_imageUrl}
                    alt="profilepic"
                  />
                </NavLink>
                <div className="user-detail">
                  <NavLink to="/">
                    <img
                      className="img-icon avatar-big"
                      src={_imageUrl}
                      alt="profilepic"
                    />
                  </NavLink>
                  <h2>{get(userDetail, "userName")}</h2>
                  <progress
                    id="userprogress"
                    value={get(userDetail, "battleLevel")}
                    max="100"
                  >
                    {" "}
                    {get(userDetail, "battleLevel")}%{" "}
                  </progress>
                  <label for="userprogress">
                    Battle Level: {get(userDetail, "battleLevel")}
                  </label>
                  <br />
                  <div className="user-icons">
                    <div className="icons d-none">
                      <img className="img-icon" src={points} alt="points" />
                      <span>{get(userDetail, "totalVersus")}</span>
                    </div>
                    <div className="icons">
                      <img className="img-icon" src={layers} alt="layers" />
                      <span>{get(userDetail, "totalCirrus")}</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <NavLink
                  className="signin"
                  to="/signin"
                  tooltip="Sign In"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <img
                    className="img-icon anonymous"
                    src={profilepic}
                    alt="profilepic"
                  />
                </NavLink>
                <ul className="registerlinks">
                  <li>
                    <NavLink to="/signin">Signin / Register</NavLink>
                  </li>
                </ul>
              </>
            )}
          </li>
        </ul>

        <ul className="icons-list">
          <li>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="/"
            >
              <img className="img-icon" src={home} alt="home" />
              <span className="menu-name">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="/result"
            >
              <img className="img-icon" src={podium} alt="podium" />
              <span className="menu-name">Battle Results</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="/profile"
            >
              <img className="img-icon" src={user} alt="user" />
              <span className="menu-name">Profile</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="/token"
            >
              <img className="img-icon" src={cart} alt="cart" />
              <span className="menu-name">Buy Token</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="/blog"
            >
              <img className="img-icon" src={blogging} alt="blogging" />
              <span className="menu-name">Blog</span>
            </NavLink>
          </li>
          <li style={{ visibility: "hidden" }}>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="/"
            >
              <img className="img-icon" src={setting} alt="setting" />
              <span className="menu-name">Settings</span>
            </NavLink>
          </li>
          <li className="coinsbtn">
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="https://www.fuzuki.io/"
              target="_blank"
              className="layer-btn"
            >
              <img
                className="img-icon"
                src={fuzukiMobileLogo}
                alt="stratis"
                style={{ height: "40px", width: "40px" }}
              />
            </NavLink>
            <NavLink
              onClick={() => {
                setShowSideBar(false);
              }}
              activeClassName="active"
              to="https://www.fuzuki.io/"
              target="_blank"
              className="poweredby"
            >
              Powered by <span>FUZUKI Inu </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
