import { combineReducers } from 'redux';
import {
    FETCH_GAMES_REQUEST,
    FETCH_GAMES_SUCCESS,
    FETCH_GAMES_FAILURE,
    SET_ACTIVE_GAME,
    SHOW_LOADER,
    STOP_LOADER,
    SET_GAME_TYPE
} from '../actions/homeAction';

const initialState = {
    loading: false,
    games: {},
    error: ""
}

const games = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GAMES_REQUEST:
            return {
                ...state, loading: true
            }
        case FETCH_GAMES_SUCCESS:
            return {
                ...state, games: action.payload, loading: false
            }
        case FETCH_GAMES_FAILURE:
            return {
                ...state, error: action.payload, loading: false
            }
        default:
            return state
    }
}

const activeGame = (state ={}, action) => {
    switch(action.type) {
        case SET_ACTIVE_GAME: 
            return { ...action.payload}
            default: 
                return state;
    }
}

const gameType = (state ={}, action) => {
    switch(action.type) {
        case SET_GAME_TYPE: 
            return { ...action.payload}
        
            default: 
                return state;
    }
}
const showLoader = (state = false, action) => {
    switch(action.type) {
        case SHOW_LOADER: 
            return action.payload;
        case STOP_LOADER: 
            return false;
        default: 
            return state;
    }
}

export default combineReducers({
    games,
    activeGame,
    showLoader,
    gameType
});