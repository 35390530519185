import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import name from "../../Pages/images/name.png";
import { doUpdateUsername } from "../../Redux/actions/authAction";
import { services } from "../../utils/services";

function debounce(func, delay) {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), delay);
  };
}

const UpdateUsername = () => {
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );

  const defaultFormData = {
    userId: get(userDetail, "userId"),
    newUserName: get(userDetail, "userName"),
  };

  const [loginForm, setLoginForm] = useState(defaultFormData);
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  //   const debouncedOnChange = useCallback(
  //     debounce((e, name) => {
  //       const _value = get(e, "target.value");
  //       setLoginForm((s) => ({ ...s, [name]: _value }));

  //       // Call the API here
  //       if (name) {
  //         services
  //           .checkUsername({ userName: _value })
  //           .then((response) => {
  //             const { status, result, message } = response;
  //             const _error = {};
  //             if (message !== "User name exist") {
  //               // Clear the error if the API response is successful
  //               _error["userName"] = "";
  //             } else {
  //               // Set the error message if the API response indicates an error
  //               _error["userName"] = message;
  //             }
  //             setError(_error);
  //           })
  //           .catch((error) => {
  //             const _error = {};
  //             _error["userName"] = "Something Went Wrong.";
  //             setError(_error);
  //           });
  //       }
  //     }, 1000),
  //     []
  //   );

  const debouncedOnChange = useCallback(
    debounce((e, name) => {
      const _value = get(e, "target.value");

      // Call the API here
      if (name) {
        services
          .checkUsername({ userName: _value })
          .then((response) => {
            const { message } = response;
            const _error = {};
            if (message === "User name exist") {
              _error["newUserName"] = message;
            } else {
              _error["newUserName"] = "";
            }
            setError(_error);
          })
          .catch((error) => {
            const _error = {};
            _error["newUserName"] = "Something Went Wrong.";
            setError(_error);
          });
      }
    }, 1000),
    []
  );

  const onChange = (e, name) => {
    const _value = get(e, "target.value");
    setLoginForm((s) => ({ ...s, [name]: _value }));
    debouncedOnChange(e, name);
  };

  //   const onChange = (e, name) => {
  //     const _value = get(e, "target.value");
  //     setLoginForm((s) => {
  //       return { ...s, [name]: _value };
  //     });
  //   };

  //   const validateForm = () => {
  //     const _error = {};
  //     if (!isEmpty(loginForm)) {
  //       if (!get(loginForm, "userName")) {
  //         _error["userName"] = "This field is required";
  //       }
  //     }
  //     setError(_error);
  //     if (!isEmpty(_error)) {
  //       return false;
  //     }
  //     return true;
  //   };

  const validateForm = () => {
    const _error = {};
    const allowedCharactersRegex = /^[a-zA-Z0-9@#]+$/;

    if (!isEmpty(loginForm)) {
      const userName = get(loginForm, "newUserName");
      if (!userName) {
        _error["newUserName"] = "This field is required";
      } else if (!allowedCharactersRegex.test(userName)) {
        _error["newUserName"] = "Invalid characters in username";
      }
    }

    setError(_error);
    if (!isEmpty(_error)) {
      return false;
    }
    return true;
  };

  const onSubmitHandler = () => {
    if (validateForm()) {
      dispatch(doUpdateUsername({ ...loginForm }));
    }
  };

  //   useEffect(() => {
  //     return () => {
  //       const token = getToken();
  //       if (!token) {
  //         dispatch(logoutUser());
  //       }
  //     };
  //   }, []);

  return (
    <div>
      <div className="modal-detail">
        <div className="info-head">
          <h2>Update Username</h2>
        </div>

        <div className="input-items">
          <div className="input-group field-input">
            <img className="img-icon" src={name} alt="username" />
            <input
              type="email"
              placeholder="Username"
              className="token-qty"
              onChange={(e) => onChange(e, "newUserName")}
              value={loginForm.newUserName}
            />
            {get(error, "newUserName") && (
              <span className="requiredMsg">{get(error, "newUserName")}</span>
            )}
          </div>
        </div>
        <button onClick={() => onSubmitHandler()} className="entergame-btn">
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateUsername;
