import { ethers } from "ethers";
import ContractABI20 from "./ContractABI20";
import HashbattleContractABI from "./HashbattleContractABI";
const { REACT_APP_PRIVATE_KEY } = process.env;

export const signForProfile = async (provider, account) => {
  try {
    const message = "Authentication message";
    const messageBytes = ethers.utils.toUtf8Bytes(message);
    const hashedMessage = ethers.utils.keccak256(messageBytes);

    const signer = provider.getSigner(account);
    const signature = await signer.signMessage(
      ethers.utils.arrayify(hashedMessage)
    );
    return { hashedMessage, signature };
  } catch (error) {
    return {
      hashedMessage: error.message,
      signature: error.message,
      err: true,
    };
  }
};

export const verifySignature = (hashedMessage, signature, expectedAddress) => {
  try {
    // Recover the signer's address from the signature
    const recoveredAddress = ethers.utils.verifyMessage(
      ethers.utils.arrayify(hashedMessage),
      signature
    );

    // Compare the recovered address with the expected address
    const isValid =
      recoveredAddress.toLowerCase() === expectedAddress.toLowerCase();
    return { isValid, recoveredAddress };
  } catch (error) {
    return { isValid: false, error: error.message };
  }
};

// export const checkAllowance = async (amount) => {
//   const contractAddressERC20 = "0xDeEBD326AFC23355C507C6A3D59D3bE86121723B";
//   const provider = new ethers.providers.Web3Provider(window.ethereum);
//   const signer = provider.getSigner();
//   const contract = new ethers.Contract(
//     contractAddressERC20,
//     ContractABI20,
//     provider
//   );
//   const contractWithSigner = contract.connect(signer);
//   const spender = "0xC77ecf339EF09307a38b1550dEB1d3f610807A26";
//   const owner = await signer.getAddress();

//   const currentAllowance = await contract.allowance(owner, spender);

//   if (currentAllowance.lt(amount)) {
//     const approveTx = await contractWithSigner.approve(spender, amount);
//     await approveTx.wait();
//   } else {
//     console.log(
//       "Current allowance is sufficient:",
//       currentAllowance.toString()
//     );
//   }
// };

export const checkAllowance = async (amount) => {
  const contractAddressERC20 = "0xDeEBD326AFC23355C507C6A3D59D3bE86121723B";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    contractAddressERC20,
    ContractABI20,
    provider
  );
  const contractWithSigner = contract.connect(signer);
  const spender = "0xcC01c63d06d0AF896A24aE5104f71B0F84D80a66";

  try {
    const owner = await signer.getAddress();
    const currentAllowance = await contract.allowance(owner, spender);

    if (currentAllowance.lt(amount)) {
      try {
        const approveTx = await contractWithSigner.approve(spender, amount);
        await approveTx.wait();
      } catch (approveError) {
        console.error(
          "User rejected the transaction or approval failed:",
          approveError
        );
      }
    }
    // else {
    //   console.log(
    //     "Current allowance is sufficient:",
    //     currentAllowance.toString()
    //   );
    // }
  } catch (error) {
    console.error(
      "An error occurred while checking or setting allowance:",
      error
    );
  }
};

export const gameContract = async (providerr, gameId, feeId) => {
  console.log(feeId);
  try {
    const contractAddress = "0xcC01c63d06d0AF896A24aE5104f71B0F84D80a66";

    const provider = new ethers.providers.Web3Provider(
      providerr.walletProvider
    );
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      HashbattleContractABI,
      signer
    );

    // const contractWithSigner = contract.connect(signer);
    const startGame = await contract.startGame(gameId, feeId);
  } catch (error) {
    console.log("eeeeeeeeeee", error);
  }
};

// export const signAndData = async (providerr) => {
//   const provider = new ethers.providers.Web3Provider(providerr.walletProvider);
//   const domain = {
//     name: "HashBattle",
//     version: "1",
//     chainId: 80002, // sepolia
//     verifyingContract: "0xcC01c63d06d0AF896A24aE5104f71B0F84D80a66",
//   };

//   const types = {
//     ScoreSubmission: [
//       { name: "gameId", type: "uint32" },
//       { name: "sessionId", type: "uint32" },
//       { name: "score", type: "uint32" },
//       { name: "nonce", type: "uint256" },
//     ],
//   };

//   const value = {
//     gameId: 11, // TokenL2 address on L2
//     sessionId: 1, // User address
//     score: 10,
//     nonce: 1,
//   };

//   // const sepoliaProvider = new ethers.JsonRpcProvider(
//   //   `https://sepolia.infura.io/v3/${INFURA_API_KEY}`
//   // );
//   const accountX = new ethers.Wallet(`0x${REACT_APP_PRIVATE_KEY}`).connect(
//     provider
//   );

//   try {
//     const SCORE_SUBMISSION_TYPEHASH = await ethers.utils.keccak256(
//       ethers.utils.solidityPack(
//         ["string"],
//         [
//           "ScoreSubmission(uint32 gameId,uint32 sessionId,uint32 score,uint256 nonce)",
//         ]
//       )
//     );

//     const abiCoder = new ethers.utils.AbiCoder();
//     const data = await abiCoder.encode(
//       ["bytes32", "uint32", "uint32", "uint32", "uint256"],
//       [
//         SCORE_SUBMISSION_TYPEHASH,
//         value.gameId,
//         value.sessionId,
//         value.score,
//         value.nonce,
//       ]
//     );

//     // not using this
//     const dataHash = await ethers.utils.keccak256(data);

//     const signature = await accountX._signTypedData(domain, types, value);
//     return signature;
//   } catch (error) {
//     console.log("error-----:", error);
//   }
// };

// export const submitScoreContract = async (providerr, signature) => {
//   try {
//     const value = {
//       gameId: 11, // TokenL2 address on L2
//       sessionId: 1, // User address
//       score: 10,
//       nonce: 1,
//     };
//     const contractAddress = "0xcC01c63d06d0AF896A24aE5104f71B0F84D80a66";

//     const provider = new ethers.providers.Web3Provider(
//       providerr.walletProvider
//     );
//     const signer = provider.getSigner();
//     const contract = new ethers.Contract(
//       contractAddress,
//       HashbattleContractABI,
//       signer
//     );

//     // const contractWithSigner = contract.connect(signer);
//     const submitScorer = await contract.submitScore(
//       value.gameId,
//       value.sessionId,
//       value.score,
//       value.nonce,
//       signature
//     );

//   } catch (error) {
//     console.log("eeeeeeeeeee", error);
//   }
// };

export const signAndData = async (providerr) => {
  const provider = new ethers.providers.Web3Provider(providerr.walletProvider);
  const domain = {
    name: "HashBattle",
    version: "1",
    chainId: 80002,
    verifyingContract: "0xcC01c63d06d0AF896A24aE5104f71B0F84D80a66",
  };

  const types = {
    ScoreSubmission: [
      { name: "gameId", type: "uint32" },
      { name: "sessionId", type: "uint32" },
      { name: "score", type: "uint32" },
      { name: "nonce", type: "uint32" },
    ],
  };

  const value = {
    gameId: 11, // TokenL2 address on L2
    sessionId: 2, // User address
    score: 10,
    nonce: 2,
  };

  // const sepoliaProvider = new ethers.JsonRpcProvider(
  //   `https://sepolia.infura.io/v3/${INFURA_API_KEY}`
  // );
  const accountX = new ethers.Wallet(`0x${REACT_APP_PRIVATE_KEY}`).connect(
    provider
  );

  try {
    const SCORE_SUBMISSION_TYPEHASH = await ethers.utils.keccak256(
      ethers.utils.solidityPack(
        ["string"],
        [
          "ScoreSubmission(uint32 gameId,uint32 sessionId,uint32 score,uint32 nonce)",
        ]
      )
    );
    const abiCoder = new ethers.utils.AbiCoder();
    const data = await abiCoder.encode(
      ["bytes32", "uint32", "uint32", "uint32", "uint32"],
      [
        SCORE_SUBMISSION_TYPEHASH,
        value.gameId,
        value.sessionId,
        value.score,
        value.nonce,
      ]
    );

    // not using this
    const dataHash = await ethers.utils.keccak256(data);

    const signature = await accountX._signTypedData(domain, types, value);
    return signature;
  } catch (error) {
    console.log("error-----:", error);
  }
};

export const submitScoreContract = async (providerr, signature) => {
  try {
    const value = {
      gameId: 11, // TokenL2 address on L2
      sessionId: 2, // User address
      score: 10,
      nonce: 2,
    };
    const contractAddress = "0xcC01c63d06d0AF896A24aE5104f71B0F84D80a66";

    const provider = new ethers.providers.Web3Provider(
      providerr.walletProvider
    );
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      HashbattleContractABI,
      signer
    );

    // const contractWithSigner = contract.connect(signer);
    const submitScorer = await contract.submitScore(
      value.gameId,
      value.sessionId,
      value.score,
      value.nonce,
      signature
    );
  } catch (error) {
    console.log("eeeeeeeeeee", error);
  }
};
