import { get, isEmpty } from "lodash";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeModal,
  hideModal,
} from "../../Redux/actions/modalActions";
import { MODAL_TYPE } from "../../utils/constant";
import { CDN_IMAGE_URL } from "../../utils/GlobalConfig";
import { startGame } from "../../Redux/actions/gameAction";
import { setGameType, showLoader } from "../../Redux/actions/homeAction";

const Modalgame = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const activeGame = useSelector((state) => get(state, "home.activeGame", {}));
  const selectedGameType = useSelector((state) =>
    get(state, "home.gameType", {})
  );
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );

  const handleClick = () => {
    if (!isEmpty(selectedGameType)) {
      dispatch(showLoader(true));
      dispatch(hideModal());
      if (!isEmpty(userDetail)) { // remove ! for checking without userdetails
        dispatch(startGame());
      } else {
        dispatch(changeModal(MODAL_TYPE.LOGIN));
      }
      dispatch(showLoader(false));
    } else {
      setError("Please Select Tier");
    }
  };

  const ChangeGameType = (tier) => {
    dispatch(setGameType(tier));
  };

  useEffect(() => {
    if (activeGame?.tiers && activeGame?.tiers?.length > 0) {
      const _tier = activeGame?.tiers?.[0] || {};
      dispatch(setGameType(_tier));
    }
  }, []);

  const getPrice = (price) => {
    try {
      const _price = price * 3;
      if (!isNaN(_price)) {
        return _price.toFixed(2);
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const _winningAmount =
    get(selectedGameType, "tierPrice") > 0
      ? getPrice(get(selectedGameType, "tierPrice"))
      : "FREE";
  const _currency =
    _winningAmount === "FREE"
      ? null
      : get(selectedGameType, "tierCurrency")?.toUpperCase();
  return (
    <div>
      <div className="modal-detail">
        <div className="info-head">
          <span>Match 3</span>
          {/* <span className="version-info">Casual 4.6</span> */}
        </div>
        <div className="game-image">
          <img
            src={CDN_IMAGE_URL + get(activeGame, "imageURL", "")}
            alt="game-name"
          />
        </div>
        <label className="select-label">Select the boot price</label>
        <div className="select-option">
          {!isEmpty(get(activeGame, "tiers")) &&
            get(activeGame, "tiers", []).map((tier, _index) => {
              const _price = get(tier, "tierPrice", "") || "free";
              return (
                <button
                  key={_index}
                  className={`btn option-btn ${
                    get(selectedGameType, "tierID") === get(tier, "tierID", "")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => ChangeGameType(tier)}
                >
                  <span>{_price}</span>
                  <br />
                  <span>{get(tier, "tierCurrency", "")}</span>
                  {/* {
                                        _index > 0 &&
                                        <span className='up-feature'><img src={lock} alt="padlock" /></span>
                                    } */}
                </button>
              );
            })}
          {error && <span>{error}</span>}
        </div>
        <button onClick={() => handleClick()} className="entergame-btn">
          Enter the game
        </button>
        <label className="info-text">
          Winning amount: {_winningAmount} {_currency}
        </label>
      </div>
    </div>
  );
};

export default Modalgame;
