import React from 'react';
import { isUserExist, showLoginPopup } from '../../Redux/actions/authAction';

import NotFound from '../../Pages/NotFoundPage';

const PrivateRoutes = ({ children }) => {
    const _isUserExist = isUserExist();


    return _isUserExist ?
    children : <NotFound />; 
    
}
export default PrivateRoutes;