import React, { Component, useEffect } from "react";
import logo from "../images/HashbattleWhite.png";
import fuzukiicon from "../images/fuzukiicon.png";
import fuzukiicon1 from "../images/fuzukiicon1.png";
import points from "../images/Points.png";
import layers from "../images/Layers.png";
import mobilelogo from "../images/mobile-logo.png";
import menu from "../images/menu.png";
import times from "../images/times.png";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserDetail,
  getUserDetail,
} from "../../Redux/actions/authAction";
import { get, isEmpty } from "lodash";
import ConnectWalletModal from "../../components/modals/ConnectWalletModal";
import fuzukiMobileLogo from "../images/fuzuki-logo-mobile.png";

const Header = (props) => {
  const { setShowSideBar, showSideBar } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateUserDetail());
    // dispatch(getUserDetail());
  }, []);
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );

  const isUserExist = !isEmpty(userDetail);
  return (
    <div className="navbar">
      {/* <nav className="navbar"> */}
      <div className="mobileview">
        {/* {showSideBar ? 
                    <a onClick={() => setShowSideBar(false)}>
                        <img className="img-icon cancel-btn" src={times} alt="menu" />
                        </a>: null } */}
        {/* { !showSideBar ?  */}
        <a onClick={() => setShowSideBar(true)}>
          <img className="img-icon bars-btn" src={menu} alt="menu" />
        </a>
        {/* : null } */}
        <img className="mobilelogo" src={fuzukiMobileLogo} alt="mobilelogo" />
      </div>
      <div className="desktopview" style={{ marginLeft: "175px" }}>
        <img className="mainlogo" src={fuzukiicon1} alt="logo" />
      </div>
      <div className="user-icons">
        <ConnectWalletModal showModal={true} />
      </div>
      {/* <div className="user-icons">
          {isUserExist && (
            <>
              <div className="icons d-none">
                <img className="img-icon" src={points} alt="points" />
                <span>{get(userDetail, "totalVersus")}</span>
              </div>
              <div className="icons">
                <img className="img-icon" src={layers} alt="layers" />
                <span>{get(userDetail, "totalCirrus")}</span>
              </div>
            </>
          )}
        </div> */}
      {/* </nav> */}
    </div>
  );
};

export default Header;
