import { services } from "../../utils/services";
import { showNotification } from "./notificationAction";
import { NOTIFICATION_TYPE } from "../../utils/constant";

export const FETCH_GAMES_REQUEST = "FETCH_GAMES_REQUEST";
export const FETCH_GAMES_SUCCESS = "FETCH_GAMES_SUCCESS";
export const FETCH_GAMES_FAILURE = "FETCH_GAMES_FAILURE";
export const SET_ACTIVE_GAME = "SET_ACTIVE_GAME";
export const SHOW_LOADER = "SHOW_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const SET_GAME_TYPE = "SET_GAME_TYPE";

export const fetchGamesRequest = () => {
    return {
     type: FETCH_GAMES_REQUEST,
    }
}

export const fetchGamesSuccess = (games) => {
    return {
     type: FETCH_GAMES_SUCCESS,
     payload: games
    }
}

export const fetchGamesFailure = (error) => {
    return {
     type: FETCH_GAMES_FAILURE,
     payload: error
    }
}

export const setActiveGame = (game) => {
    return {
        type: SET_ACTIVE_GAME,
        payload: game
    }
}

export const showLoader = (isShow) => {
    return {
        type: SHOW_LOADER,
        payload: isShow
    }
}

export const setGameType = (type) => {
    return {
        type: SET_GAME_TYPE,
        payload: type
    }
}

export const fetchGames = () => {
    return (dispatch) => {
        dispatch(fetchGamesRequest());
        services.getGames().then((response) => {
            dispatch(fetchGamesSuccess(response))
            // dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, "successfully loaded."))
            dispatch(showLoader(false))
        }).catch(error => {
            dispatch(showLoader(false))
            dispatch(showNotification(NOTIFICATION_TYPE.ERROR, "something went wrong."))
            dispatch(fetchGamesFailure(error));
        });
    }
}