import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Link } from 'react-router-dom'
import matchking from '../images/matchking.png';
import points from '../images/Points.png';
import layers from '../images/Layers.png';
import profilepic from '../images/avatar.jpg';
import crown from '../images/crown.png'
import { services } from '../../utils/services';
import { getUserDTO } from '../../Redux/actions/authAction';
import { CDN_IMAGE_URL, LIVE_IMAGE_URL } from '../../utils/GlobalConfig';
import { useDispatch } from 'react-redux';
import { showLoader } from '../../Redux/actions/homeAction';
import { getCurrentDate } from '../../utils';
import { TIER_CURRENCY_TYPE } from '../../utils/constant'




const Result = () => {
    const [results, setResults] = useState([]);
    const dispatch = useDispatch();
    const getResults = async () => {
        const _userId = getUserDTO();
        if (_userId) {
            dispatch(showLoader(true))
            const response = await services.getResults(_userId);
            if (!isEmpty(response)) {
                dispatch(showLoader(false))
                setResults(response)
            } else {
                dispatch(showLoader(false))
            }
        }
    }

    useEffect(() => {
        getResults()
    }, [])

    return (
        <div>
            <div className="container">
                <h2 className="profileheading battleresult">Battle Results</h2>

                <div className="result-list">
                    {
                        !isEmpty(results) ?
                            results.map((result, index) => {
                                return (
                                    <div key={index} className="result-status">
                                        <div className="status-info">
                                            <span className="time">{getCurrentDate(get(result, 'endDateTime'))}</span>
                                            <>
                                                {
                                                    !get(result, "isGameOver") ?
                                                        <span className='pending status-btn'>{"Pending"}</span>
                                                        : get(result, "userId") === get(result, "winnerUserId") ?
                                                            <span className='win status-btn'> {"Won"}</span>
                                                            : <span className='lost status-btn'> {"Lost"}</span>

                                                }
                                            </>
                                        </div>
                                        <div key={index} className="result-item">
                                            <div className="img-box">
                                                <div className="game-img">
                                                    <img src={CDN_IMAGE_URL + get(result, 'gameImageURL')} alt="game-name" />
                                                    <br />
                                                    <span>{get(result, 'gameName')}</span>
                                                </div>
                                                <div className='token-count'>
                                                    {
                                                        get(result, "tierCurrency", "") === TIER_CURRENCY_TYPE.CIRRUS ?
                                                            <img className="img-icon" src={layers} alt="points" />
                                                            : <img className="img-icon" src={points} alt="points" />

                                                    }
                                                    <span>{get(result, 'tierPrice')}</span>
                                                </div>
                                            </div>
                                            <div className="result-detail">

                                                {
                                                    !isEmpty(get(result, 'participantsUser')) &&
                                                    get(result, 'participantsUser', []).map((player, _pIndex) => {
                                                        const _imageUrl = get(player,"image","") ? `${LIVE_IMAGE_URL}${get(player, 'image')}`: profilepic;
                                                        return <div className="winner-status">
                                                            {
                                                                get(result, "isGameOver") && get(result, "winnerUserId") === get(player, "userId") ?
                                                                    <img className="winner-crown" src={crown} alt="crown" /> : null
                                                            }

                                                            <Link to="/"><img className="img-icon avatar" src={_imageUrl} alt="profilepic" /></Link>
                                                            <h2>{get(player, 'userName')}</h2>
                                                            <span className="points">{get(player, 'score')}</span>
                                                        </div>

                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className="text-light profileheading battleresult">
                                <h4>
                                    No Result Found

                                </h4>
                            </div>
                    }
                </div>

            </div>

        </div>
    )
}

export default Result
