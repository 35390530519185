const HashbattleContractABI = [
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "address", name: "_adminAddress", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "ECDSAInvalidSignature", type: "error" },
  {
    inputs: [{ internalType: "uint256", name: "length", type: "uint256" }],
    name: "ECDSAInvalidSignatureLength",
    type: "error",
  },
  {
    inputs: [{ internalType: "bytes32", name: "s", type: "bytes32" }],
    name: "ECDSAInvalidSignatureS",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "uint32", name: "gameId", type: "uint32" },
      {
        indexed: true,
        internalType: "uint32",
        name: "sessionId",
        type: "uint32",
      },
      { indexed: true, internalType: "address", name: "user", type: "address" },
      { indexed: false, internalType: "uint32", name: "score", type: "uint32" },
    ],
    name: "ScoreSubmitted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "uint32", name: "gameId", type: "uint32" },
      {
        indexed: true,
        internalType: "uint32",
        name: "sessionId",
        type: "uint32",
      },
      { indexed: true, internalType: "uint256", name: "fee", type: "uint256" },
    ],
    name: "SessionCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "uint32", name: "gameId", type: "uint32" },
      {
        indexed: true,
        internalType: "uint32",
        name: "sessionId",
        type: "uint32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "winner",
        type: "address",
      },
    ],
    name: "SessionEnded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "uint32", name: "gameId", type: "uint32" },
      {
        indexed: true,
        internalType: "uint32",
        name: "sessionId",
        type: "uint32",
      },
      { indexed: true, internalType: "address", name: "user", type: "address" },
    ],
    name: "UserJoinedSession",
    type: "event",
  },
  {
    inputs: [
      { internalType: "uint8", name: "_feeId", type: "uint8" },
      { internalType: "uint256", name: "_newFees", type: "uint256" },
    ],
    name: "changeFees",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint32", name: "_gameId", type: "uint32" }],
    name: "deleteGame",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint32", name: "_gameId", type: "uint32" },
      { internalType: "uint32", name: "_sessionId", type: "uint32" },
    ],
    name: "getSessionPlayersAndScore",
    outputs: [
      { internalType: "address[]", name: "", type: "address[]" },
      { internalType: "uint32[]", name: "", type: "uint32[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint32", name: "_gameId", type: "uint32" },
      { internalType: "uint32", name: "_sessionId", type: "uint32" },
    ],
    name: "getSessionWinner",
    outputs: [{ internalType: "address", name: "winner", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint32", name: "_gameId", type: "uint32" }],
    name: "launchNewGame",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint32", name: "_gameId", type: "uint32" },
      { internalType: "uint8", name: "_feeId", type: "uint8" },
    ],
    name: "startGame",
    outputs: [{ internalType: "uint32", name: "_sessionId", type: "uint32" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint32", name: "_gameId", type: "uint32" },
      { internalType: "uint32", name: "_sessionId", type: "uint32" },
      { internalType: "uint32", name: "_score", type: "uint32" },
      { internalType: "uint32", name: "_nonce", type: "uint32" },
      { internalType: "bytes", name: "_signature", type: "bytes" },
    ],
    name: "submitScore",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
];

export default HashbattleContractABI;
