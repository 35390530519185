import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Card from '../../components/Card';

// import {services} from '../../utils/services';


const Gamelist = ({ games }) => {
    return (
        <div className="maingames">
            <div className="gamelist row">
                {
                    games.map((game, index) => {
                        return (
                            <Card
                                key={index}
                                game={game}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Gamelist
