/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import modalReducer from './modalReducer';
import homeReducer from './homeReducers';
import gameReducer from './gameReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
 modal: modalReducer,
 auth: AuthReducer,
 home: homeReducer,
 game: gameReducer,
 notification: notificationReducer
});