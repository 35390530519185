import React from 'react';
import banner1 from '../../Pages/images/Banner1.jpg';
import banner2 from '../../Pages/images/Banner2.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const CarouselBox = () => {
    return (
        <Carousel autoPlay={true} interval={5000} transitionTime={2000} showThumbs={false} infiniteLoop={true} animationHandler="fade" swipeable={false}>
            <div>
                <img className="img-fluid" src={banner1} alt="banner1" />
            </div>
            <div>
                <img className="img-fluid" src={banner2} alt="banner2" />
            </div>
        </Carousel>

    )
}

export default CarouselBox;