export const MODAL_TYPE = {
    LOGIN: 'LOGIN',
    REGISTRATION: 'REGISTRATION',
    GAME_MODEL: 'GAME_MODEL',
    OTP: 'OTP',
    CASHOUT: 'CASHOUT',
    NONE: 'NONE',
    UPDATEUSERNAME:'UPDATEUSERNAME'
} 

export const ROUTES_TYPE = {
    PRIVATE_ROUTES: "PRIVATE_ROUTES",
    PUBLIC_ROUTES: "PUBLIC_ROUTES"  
}

export const NOTIFICATION_TYPE = {
    SUCCESS: "success",
    ERROR: "danger"
}

export const TIER_CURRENCY_TYPE = {
    CIRRUS: "Cirrus",
    VERSUS: "versus"
}