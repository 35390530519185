import React from 'react';
import layers from '../images/Layers.png';
import points from '../images/Points.png';
import lock from '../../Pages/images/padlock.png';


const BuyToken = () => {

    return (
        <div>
            <div className="container">
                <h2 className="profileheading">Buy Tokens</h2>
                <div className="tokenbox">
                    <div className="token-detail">
                        <div className="icons">
                            <div className="token-info d-none">
                                <img className="img-icon" src={points} alt="points" />
                                <span className="token-name">Versus (VERSUS)</span>
                                <br />
                                <span className="token-value">$0.0</span><span className="token-percent">0%</span>
                            </div>
                            <div className=" token-info">
                                <img className="img-icon" src={layers} alt="layers" />
                                <span className="token-name">Cirrus (CRS)</span>
                                <br />
                                <span className="token-value">$0.0</span><span className="token-percent">0%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profilebox">
                    <div className="performance-info">
                        <div className="game-info">
                            <h2>Select the token you want to purchase</h2>
                        </div>

                        <div className="game-info">
                            <div className="info-group group-back">
                                <label>Purchase Limit </label>
                                <span className="limit-info"> Min: 1</span>
                                <span className="limit-info"> Max: 50</span>

                            </div>
                        </div>
                    </div>

                    <div className="select-token">
                        <button className="btn btn-default d-none">Versus</button>
                        <button className="btn btn-default active">Cirrus</button>
                    </div>
                    <div className="input-group token-input">
                        <img className="img-icon" src={points} alt="points" />
                        <input type="number" placeholder='Enter number of tokens' className="token-qty" />
                    </div>
                    <div className="token-text">
                        <span>Total amount to be Paid: 0 USD</span>
                        <br />
                        <button className="btn btn-default buy-btn active">Buy<span className='up-feature'><img src={lock} alt="padlock" /></span></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyToken
