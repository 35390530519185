import React, { useEffect, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import Iframe from 'react-iframe';

const IframeView = forwardRef((props, ref) => {
    useEffect(() => {
        
    }, []);
    return (
        <iframe
            src={props.link}
            width="100%"
            height="100%"
            id="myId"
            className="myClassname"
            display="initial"
            ref={ref}
        >

        </iframe>
            // <Iframe 
            //     url={"/iframe-child"}
            //     width="100%"
            //     height="100%"
            //     id="myId"
            //     className="myClassname"
            //     display="initial"
            //     ref={ref}
            //     allow="fullscreen" />
    )
}
)

export default IframeView;
