import React, { useEffect, useState } from 'react';
import matchking from '../images/matchking.png';
import { useParams } from 'react-router-dom';
import { services } from '../../utils/services';
import { useDispatch } from 'react-redux';
import { showLoader } from '../../Redux/actions/homeAction';
import { get, isEmpty } from 'lodash';
import { getCurrentDate } from '../../utils';
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
    LinkedinIcon
} from 'react-share';



const BlogDetail = () => {
    const shareurl = window.location.href;
    const [blogDetail, setBlogDetail] = useState(null);
    let { slug } = useParams();
    const dispatch = useDispatch();

    const fetchBlog = async () => {
        dispatch(showLoader(true))
        const _blog = await services.getBlogBySlug(slug)
        if (_blog) {
            setBlogDetail(_blog);
            dispatch(showLoader(false))
        }
    }
    useEffect(() => {
        fetchBlog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            {
                !isEmpty(blogDetail) ?
                    <div className="container">
                        <h2 className="page-heading">Blog</h2>
                        <div className="blog-post">
                            <h1>{get(blogDetail, 'title')}</h1>
                            <span className="datetime">{getCurrentDate(get(blogDetail, 'updatedOn'))}</span>
                            <img src={matchking} alt="game-name" />
                            <p>{get(blogDetail, 'blogContent')}</p>
                            <div className="sociabuttons">
                                <FacebookShareButton url={shareurl}><FacebookIcon size={32} round={true}>Share</FacebookIcon></FacebookShareButton>
                                <TwitterShareButton url={shareurl}><TwitterIcon size={32} round={true}>Tweet</TwitterIcon></TwitterShareButton>
                                <PinterestShareButton url={shareurl}><PinterestIcon size={32} round={true}>Pin it</PinterestIcon> </PinterestShareButton>
                                <LinkedinShareButton url={shareurl}><LinkedinIcon size={32} round={true}>Share </LinkedinIcon></LinkedinShareButton>
                            </div>
                        </div>
                        {/* <div className="blogseq">
                    <button className="seqbutton">Previous</button>
                    <button className="seqbutton">Next</button>
                </div> */}
                    </div> : null

            }
        </div>
    )
}

export default BlogDetail
