import { isEmpty } from "lodash";
import { setActiveGame, showLoader } from "./homeAction";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const showModal = (modalType) => {
    return {
     type: SHOW_MODAL,
     payload: modalType
    }
}

export const hideModal = () => {
    return {
     type: 'HIDE_MODAL'
    }
}

export const hideLoginModal = () => {
    return (dispatch) => {
        // dispatch(showLoader(true))
        dispatch(hideModal());
        // window.location.href = "/";
        // dispatch(showLoader(false))
    }
}

export const showActiveGame = (modalType, game) => {
    return (dispatch) => {
        if(!isEmpty(game)) {
            dispatch(setActiveGame(game))
        }
        dispatch(showModal(modalType));
    }
}

export const changeModal = (modalType) => {
    return (dispatch) => {
        dispatch(hideModal());
        dispatch(showModal(modalType))
    }
}