import {isEmpty, isObject} from 'lodash';

export const setValue = (value) => {
    if(isObject(value)) {
        if(isEmpty(value)) {
            return JSON.stringify(value)
        }
        return null;
    }
    return value;
}

export const isJson = (str) => {
    try{
        JSON.parse(str);
    } catch(e) {
        return false;
    }
    return true;
}
export const getValue = (value) => {
    if(isJson(value)) {
        const _value = JSON.parse(value);
        if(isObject(_value)) {
            return _value;
        }
    }
    return value;
}

export const getCurrentDate = (value) => {
    const date = new Date(value);
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const _months =  months[date?.getMonth()]
    const _date = `${_months} ${date?.getDate()}, ${date?.getFullYear()}`;
    return _date;
}