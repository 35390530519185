import { React, useState } from "react";
import name from "../../Pages/images/name.png";
import email from "../../Pages/images/email.png";
import password from "../../Pages/images/password.png";
import layers from "../../Pages/images/Layers.png";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { services } from "../../utils/services";
import { NOTIFICATION_TYPE } from "../../utils/constant";
import { hideModal } from "../../Redux/actions/modalActions";
import { showNotification } from "../../Redux/actions/notificationAction";
import { showLoader } from "../../Redux/actions/homeAction";

const Cashout = () => {
  const userDetail = useSelector((state) =>
    get(state, "auth.userDetail.user", {})
  );
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const onChange = (e, name) => {
    let _value = get(e, "target.value", "");
    if (name === "numberOfToken" && _value && !isNaN(_value)) {
      _value = Number(_value);
    }
    setData((d) => {
      return { ...d, [name]: _value };
    });
  };

  const validateForm = () => {
    const _error = {};
    if (!get(data, "password")) {
      _error["password"] = "This field is required";
    }
    if (!get(data, "numberOfToken")) {
      _error["numberOfToken"] = "This field is required";
    } else if (get(data, "numberOfToken") < get(userDetail, "totalCirrus")) {
      _error["numberOfToken"] = "Quantity should be less than total amount.";
    } else if (get(data, "numberOfToken") > get(userDetail, "totalCirrus")) {
      _error["numberOfToken"] =
        "Quantity should be less than total token count.";
    }
    setError(_error);
    if (!isEmpty(_error)) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validateForm()) {
      doCashout(data);
    }
  };

  const doCashout = async () => {
    const param = {
      ...data,
      userId: get(userDetail, "userId"),
      destinationAddress: get(userDetail, "cirrusAddress"),
    };
    try {
      dispatch(showLoader(true));
      const response = await services.doCashout(param);
      if (response) {
        const { message, status } = response;
        if (status) {
          dispatch(showLoader(false));
          dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, message));
        } else {
          dispatch(showLoader(false));
          dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message));
        }
      }
    } catch (err) {
      dispatch(showLoader(false));
      dispatch(
        showNotification(NOTIFICATION_TYPE.ERROR, "Something went wrong.")
      );
    }
  };
  return (
    <div>
      <div className="modal-detail">
        <div className="performance-info">
          <div className="game-info">
            <h2>Cashout</h2>
          </div>

          <div className="game-info">
            <div className="info-group group-back">
              <div className="user-icons">
                {/* <div className="icons">
                                    <img className="img-icon" src={points} alt="points" />
                                    <span>95000</span>
                                </div> */}
                <div className="icons">
                  <img className="img-icon" src={layers} alt="layers" />
                  <span>{get(userDetail, "totalCirrus")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h3 className="selectheading">Select a token to cashout.</h3> */}

        {/* <div className="select-token">
                    <button className="btn btn-default active">Versus</button>
                    <button className="btn btn-default">Strax</button>
                </div> */}
        <div className="input-items">
          <div className="input-group field-input">
            <img className="img-icon" src={name} alt="email" />
            <input
              disabled={true}
              type="text"
              value={get(userDetail, "userName")}
              placeholder="username"
              className="token-qty"
              onChange={(e) => {
                onChange(e, "userId");
              }}
            />
          </div>
          <div className="input-group field-input">
            <img className="img-icon" src={password} alt="password" />
            <input
              type="password"
              placeholder="Password"
              className="token-qty"
              onChange={(e) => {
                onChange(e, "password");
              }}
            />
            {
              <span className="requiredMsg">
                {get(error, "password") && get(error, "password")}
              </span>
            }
          </div>
          <div className="input-group field-input">
            <img className="img-icon" src={layers} alt="name" />
            <input
              type="number"
              min="0"
              value={get(data, "numberOfToken")}
              placeholder="Enter Cirrus Quantity"
              onChange={(e) => {
                onChange(e, "numberOfToken");
              }}
              className="token-qty"
            />
            {
              <span className="requiredMsg">
                {get(error, "numberOfToken") && get(error, "numberOfToken")}
              </span>
            }
          </div>
          <div className="input-group field-input">
            <img className="img-icon" src={email} alt="email" />
            <input
              disabled={true}
              type="text"
              value=""
              placeholder="Destination Address"
              className="token-qty"
            />
          </div>
          <div className="input-group field-input">
            Note: This feature will work when contract will be deployed on
            mainnet.
          </div>
        </div>
        <div className="button-div">
          <button
            className="entergame-btn cash-btn active"
            onClick={() => {
              onSubmit();
            }}
          >
            Cashout
          </button>
          <button
            className="entergame-btn cancel-btn"
            onClick={() => {
              dispatch(hideModal());
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cashout;
