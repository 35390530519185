import { combineReducers } from 'redux';
import {
    START_GAME,
    PLAYING_GAME,
    STOP_GAME
} from '../actions/gameAction';

const initialState = {
    start: false,
    // playing:false,
    // stop: false
}

const playGame = (state = initialState, action) => {
    switch (action.type) {
        case START_GAME:
            return {
                ...state, start: true
            }
        // case PLAYING_GAME:
        //     return {
        //         ...state, start: false, playing: true
        //     }
        case STOP_GAME:
            return {
                ...state, start: false
            }
        default:
            return state
    }
}

export default combineReducers({
    playGame
});