import React, { useState } from 'react';
import { get } from 'lodash';
import matchking from '../images/thumb.jpeg';
import { services } from '../../utils/services';
import { showNotification } from '../../Redux/actions/notificationAction';
import { NOTIFICATION_TYPE } from '../../utils/constant';
import { useDispatch } from 'react-redux';


const NewBlog = () => {
    const dispatch = useDispatch();
    const [blog, setBlog] = useState({ blogId: 0 });
    const [error, setError] = useState({});
    const onChange = (e, name) => {
        const _value = get(e, 'target.value');
        setBlog(s => {
            return { ...s, [name]: _value }
        })
    }
    const uploadImage = (e, name) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let inputData = reader.result;
            let replaceValue = inputData.split(",")[0];
            let base64String = inputData.replace(replaceValue + ".", "");
            setBlog(s => {
                return { ...s, [name]: base64String }
            })
        }
    }
    function convertToSlug(Text) {
        return Text.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    }
    const onSubmit = async() => {
        let isErrorExist = false;
        if (!get(blog, 'title')) {
            updateError("title")
            isErrorExist = true;
        }
        if (!get(blog, 'description')) {
            updateError("description")
            isErrorExist = true;
        }

        if (!isErrorExist) {
            const _blog = {...blog}
            const slug = await convertToSlug(get(blog, 'title', ''));
            _blog.slug = slug;
            _blog.blogContent = "content";
            const response = await services.updateBlog(_blog);
            if(response) {
                const {status, message } = response;
                if(status) {
                    dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, message))
                } else {
                    dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message))
                }
            } 
        }

    }
    const updateError = (name) => {
        setError(s => {
            return { ...s, [name]: "This field is required." }
        })
    }

    const _url = get(blog, 'image') || matchking;

    return (
        <div>
            <div className="container">
                <h2 className="profileheading battleresult">Add Blog</h2>
                <div className='blog-form'>
                    <div className='blog-input file-input'>
                        <img className='blog-image' src={_url} alt="blog" />
                        <div className='uploader-text'><span>Upload an image</span></div>
                        <input className='blog-image-uploader' name='image' type='file' onChange={e => uploadImage(e, "image")} />
                    </div>
                    <div className='blog-input'>
                        <label>Blog Title</label>
                        {/* onChange={e => onChange(e, 'password')} */}
                        <input className='blog-title' name='title' placeholder='Add your blog title' onChange={e => onChange(e, 'title')} type='text' />
                        {
                            get(error, 'title') &&
                            <span className='requiredMsg'>
                                {get(error, 'title')}
                            </span>

                        }
                    </div>
                    <div className='blog-input'>
                        <label>Blog Description</label>
                        <textarea className='blog-desc' placeholder='Add your blog description' onChange={e => onChange(e, 'description')} name='description' rows='5'></textarea>
                        {
                            get(error, 'description') &&
                            <span className='requiredMsg'>
                                {get(error, 'description')}
                            </span>

                        }
                    </div>
                    <div className='blog-input'>
                        <input className='blog-submit' value='Add' type='submit' onClick={() => { onSubmit() }} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NewBlog
