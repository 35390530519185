import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGames, showLoader } from '../../Redux/actions/homeAction';
import Gamelist from '../Home';
import CarouselBox from '../../components/CarouselBox';
import gamesConstant from "../../utils/gamesMock";


const HomeBody = () => {
    const dispatch = useDispatch();
    const gamesDetail = useSelector(state => get(state, 'home.games', {})) || {};
    // const otpDetail = useSelector(state => get(state, 'auth.otpDetail', ""));
    useEffect(() => {
        if (isEmpty(gamesDetail?.games)) {
            dispatch(showLoader(true))
            dispatch(fetchGames());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="container containerbox">

                <div className='main-banner'>
                    <CarouselBox />
                </div>

                {
                    !isEmpty(get(gamesDetail, 'games.gamesOfWeek', [])) &&
                    <div className="gamebox">
                        <h2>Top Picks</h2>
                        {
                            <Gamelist
                                games={get(gamesDetail, 'games.gamesOfWeek', [])}
                            />
                        }
                    </div>
                }
                <div className="gamebox">
                    <h2>All Games</h2>
                    <Gamelist
                        games={get(gamesDetail, 'games.popularGames', gamesConstant)}
                    />
                </div>
            </div>

        </div>
    )
}

export default HomeBody
