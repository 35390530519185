import { combineReducers } from 'redux';
import {SHOW_MODAL, HIDE_MODAL} from '../actions/modalActions';
import { MODAL_TYPE } from '../../utils/constant';
const initialState = {
    type: MODAL_TYPE.NONE,
}

const modalTypes = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state, type: action.payload }
        case HIDE_MODAL:
            return {
                ...state, type: MODAL_TYPE.NONE }
        default:
            return state
    }
}

export default combineReducers({
    modalTypes
});