import React from "react";
import { Link } from "react-router-dom";
import facebook from "../images/facebook.png";
import discord from "../images/discord.png";
import twitterr from "../images/twitterr.png";
import instagram from "../images/instagram.png";
import telegram from "../images/telegram.png";
import youtube from "../images/youtube.png";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="footerbox">
          <div className="container containerbox">
            <div className="row" style={{ alignItems: "center" }}>
              {/* <div
                className="col-md-4 d-none d-md-block"
                style={{ textAlign: "left" }}
              >
                <p className="footertext">� Hashbattle Network 2021</p>
              </div> */}
              <div
                className="col-md-6 col-sm-12 text-center text-md-right"
                style={{ textAlign: "right" }}
              >
                <p className="footertext">
                  Powered by FUZUKI Inu / Polygon Blockchain
                </p>
              </div>
              <div
                className="col-md-6 d-none d-md-block"
                style={{ textAlign: "center" }}
              >
                <Link
                  to="https://m.facebook.com/fuzuki.inu"
                  target="_blank"
                  className="social-links"
                >
                  <img className="social-icon" src={facebook} alt="facebook" />
                </Link>
                <Link
                  to="https://twitter.com/Fuzuki_Inu"
                  target="_blank"
                  className="social-links"
                >
                  <img className="social-icon" src={twitterr} alt="twitter" />
                </Link>

                <Link
                  to="https://t.me/fuzukitoken"
                  target="_blank"
                  className="social-links"
                >
                  <img className="social-icon" src={telegram} alt="telegram" />
                </Link>
                <Link
                  to="https://www.instagram.com/fuzukiinu/"
                  target="_blank"
                  className="social-links"
                >
                  <img
                    className="social-icon"
                    src={instagram}
                    alt="instagram"
                  />
                </Link>
                <a
                  target="_blank"
                  href=" https://www.youtube.com/@FuzukiArt"
                  title="Youtube"
                >
                  <img className="social-icon" src={youtube} alt="youtube" />
                </a>
                <a
                  target="_blank"
                  href=" https://discord.com/invite/VA8dtvtpce"
                  title="Discord"
                >
                  <img className="social-icon" src={discord} alt="discord" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
