import { httpRequest } from "../utils/axios";
import { get } from "lodash";
import {
  loginUserApi,
  registerUserApi,
  getGamesApi,
  getUserDetailApi,
  getUserResultsApi,
  getBlogsApi,
  getBlogBySlugApi,
  doStartPlayApi,
  registerOTPVerificationApi,
  loginOTPVerificationApi,
  resendOTPApi,
  updateBlogApi,
  uploadProfileImageApi,
  doCashoutApi,
  cashoutOTPVerificationApi,
  updateUsernameApi,
  checkUsernameApi,
} from "./repository";

class Services {
  loginUser(param) {
    loginUserApi.data = param;
    return httpRequest(loginUserApi).then((response) => {
      const res = get(response, "data", {});
      const { status, result, message, error, token } = res;
      return { result, message, status, error, token };
    });
  }
  registerUser(param) {
    registerUserApi.data = param;
    return httpRequest(registerUserApi)
      .then((response) => {
        const { data: { status, result, message, error } = {} } = response;
        // if(status) {
        return { status, result, message, error };
        // }
        // throw error;
      })
      .catch((e) => {
        return e;
      });
  }

  registerOTPVerification(param) {
    registerOTPVerificationApi.data = param;
    return httpRequest(registerOTPVerificationApi)
      .then((response) => {
        const { data: { status, message, error, isValidateOtp } = {} } =
          response;
        if (status) {
          return { status, message };
        } else if (!isValidateOtp && message) {
          return { isValidateOtp, message };
        }
        throw error;
      })
      .catch((e) => {
        return e;
      });
  }
  resendOTP(oid) {
    resendOTPApi.url = `user/otp/re-send/${oid}`;
    return httpRequest(resendOTPApi).then((response) => {
      const { data: { status, result, message, error } = {} } =
        response;
      if (status) {
        return { status, result, message };
      }
      throw error;
    });
  }

  loginOTPVerification(param) {
    loginOTPVerificationApi.data = param;
    return httpRequest(loginOTPVerificationApi)
      .then((response) => {
        const {
          data: { status, result, message, error, isValidateOtp, token } = {},
        } = response;
        // debugger
        if (status) {
          return { status, message, token, result };
        } else if (!isValidateOtp && message) {
          return { isValidateOtp, message };
        }
        throw error;
      })
      .catch((e) => {
        return e;
      });
  }

  getuserDetail(userId) {
    getUserDetailApi.url = `User/get/${userId}`;
    return httpRequest(getUserDetailApi).then((response) => {
      const {
        data: { status, result },
      } = response;
      if (status) {
        return { result };
      }
      return null;
    });
  }

  getBlogs(param) {
    return httpRequest(getBlogsApi)
      .then((response) => {
        const { data: { status, result } = {} } = response;
        if (status) {
          return result;
        }
        return [];
      })
      .catch((e) => {
        return [];
      });
  }

  getResults(userId) {
    getUserResultsApi.url = `battle/userhistory/${userId}`;
    return httpRequest(getUserResultsApi)
      .then((response) => {
        const { data: { status, result } = {} } = response;
        if (status) {
          return result;
        }
        return [];
      })
      .catch((e) => {
        return [];
      });
  }

  getGames() {
    return httpRequest(getGamesApi)
      .then((response) => {
        const {
          data: { status, result },
        } = response;
        if (status) {
          return result;
        }
        return {};
      })
      .catch((e) => {
        return null;
      });
  }
  getBlogBySlug(slug) {
    getBlogBySlugApi.url = `blog/get/slug/${slug}`;
    return httpRequest(getBlogBySlugApi)
      .then((response) => {
        const {
          data: { status, result },
        } = response;
        if (status) {
          return result;
        }
        return {};
      })
      .catch((e) => {
        return null;
      });
  }
  doStartPlay(params) {
    doStartPlayApi.data = params;
    return httpRequest(doStartPlayApi)
      .then((response) => {
        const {
          data: { status, result, message },
        } = response;
        return { status, result, message };
      })
      .catch((e) => {
        return { status: false, message: "Something went wrong." };
      });
  }
  updateBlog(params) {
    updateBlogApi.data = params;
    return httpRequest(updateBlogApi)
      .then((response) => {
        const {
          data: { status, result, message },
        } = response;
        return { status, result, message };
      })
      .catch((e) => {
        return null;
      });
  }

  uploadProfileImage(param) {
    uploadProfileImageApi.data = param;
    return httpRequest(uploadProfileImageApi)
      .then((response) => {
        const {
          data: { status, result, message },
        } = response;
        return { status, result, message };
      })
      .catch((e) => {
        return null;
      });
  }

  updateUsername(param) {
    updateUsernameApi.data = param;
    return httpRequest(updateUsernameApi)
      .then((response) => {
        const {
          data: { status, result, message },
        } = response;
        return { status, result, message };
      })
      .catch((e) => {
        return null;
      });
  }

  checkUsername(param) {
    checkUsernameApi.data = param;
    return httpRequest(checkUsernameApi)
      .then((response) => {
        const {
          data: { status, result, message },
        } = response;
        return { status, result, message };
      })
      .catch((e) => {
        return null;
      });
  }

  doCashout(param) {
    doCashoutApi.data = param;
    return httpRequest(doCashoutApi).then((response) => {
      const {
        data: { status, result, message },
      } = response;
      return { status, result, message };
    });
  }
  cashoutOTPVerification(param) {
    cashoutOTPVerificationApi.data = param;
    return httpRequest(cashoutOTPVerificationApi)
      .then((response) => {
        const { data: { status, message, error, isValidateOtp } = {} } =
          response;
        if (status) {
          return { status, message };
        } else if (!isValidateOtp && message) {
          return { isValidateOtp, message };
        }
        throw error;
      })
      .catch((e) => {
        return e;
      });
  }
}

export const services = new Services();
