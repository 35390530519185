import React, { useEffect } from "react";
import { services } from "../../utils/services";
import { useDispatch } from "react-redux";
import { showLoader } from "../../Redux/actions/homeAction";
import Slider from "react-slick";
import Globenewswire from "../images/BlogImages/globenewswire.png";
import Businessheadline from "../images/BlogImages/business-headline.png";
import Beamstart from "../images/BlogImages/beamstart.png";
import Yahoo from "../images/BlogImages/yahoo-finance.png";
import Nevadabusinessherald from "../images/BlogImages/nevadabusinessherald.png";
import Einnews from "../images/BlogImages/einnews.png";
import Einpresswire from "../images/BlogImages/einpresswire.png";
import Todayinbusiness from "../images/BlogImages/todayinbusiness.png";
import Gdc from "../images/BlogImages/gdc-einnews.png";
import Ems from "../images/BlogImages/ems-einnews.png";
import printing from "../images/BlogImages/3dprinting.png";

const Blog = () => {
  // const [blogs, setBlogs] = useState([]);
  const dispatch = useDispatch();
  const getBlogs = async () => {
    dispatch(showLoader(true));
    const response = await services.getBlogs();
    if (response) {
      // setBlogs(response);
      dispatch(showLoader(false));
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="container">
        <div className="blog-head" style={{ justifyContent: "center" }}>
          <span className="profileheading">Blogs</span>
          {/* <Link className='btn add-blog-btn' to='/addblog'> Add Blog</Link> */}
        </div>
        {/* <div className="blog-list">
                    {
                        !isEmpty(blogs) &&
                        blogs.map((blog, index) => {
                            const _imageUrl = get(blog, 'image') ? `${LIVE_IMAGE_URL}${get(blog, 'image')}` : matchking;
                            const _date = getCurrentDate(get(blog, 'updatedOn'));
                            return <div key={get(blog, 'blogId')} className="blog-item">
                                <div className="img-box">
                                    <Link to={`/blog/${get(blog, 'slug')}`}>
                                        <img src={_imageUrl} alt="game-name" />
                                    </Link>
                                </div>
                                <div className="blog-detail">
                                    <Link to={`/blog/${get(blog, 'slug')}`}><h2>{get(blog, 'title')}</h2></Link>
                                    <p>{get(blog, 'description')}</p>
                                    <span>{_date}</span>
                                </div>
                            </div>
                        })
                    }
                </div> */}
        <div>
          <div className="client-testimonial">
            <div className="container">
              <div className="section-title mb-5" style={{ marginTop: "80px" }}>
                <Slider {...settings}>
                  <a
                    className="testimonial-box"
                    href="https://www.globenewswire.com/en/search/organization/Fuzuki%2520Inu"
                    title="Globenewswire"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Globenewswire} alt="globenewswire" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://businessheadline.in/brand-post/crypto/fuzuki-inu-the-shiba-kanji-pre-sale-success-journey/"
                    title="Businessheadline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Businessheadline} alt="business-headline" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://beamstart.com/news/fuzuki-inu--the-shiba-17132726604820"
                    title="Beamstart"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Beamstart} alt="beamstart" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://finance.yahoo.com/news/fuzuki-inu-shiba-kanji-pre-130000826.html"
                    title="Yahoo Finance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Yahoo} alt="Yahoo Finance" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://www.nevadabusinessherald.com/article/695960788-fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="Nevadabusinessherald"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img
                        src={Nevadabusinessherald}
                        alt="nevadabusinessherald"
                      />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://www.einnews.com/pr_news/695960788/fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="Einnews"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Einnews} alt="einnews" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://3dprinting.einnews.com/pr_news/695960788/fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="3dprinting Einnews"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={printing} alt="3dprinting" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://www.einpresswire.com/article/695960788/fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="Einpresswire"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Einpresswire} alt="einpresswire" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://businessheadline.in/brand-post/technology/announcing-fuzuki-inu-the-shiba-kanji-pre-sale-dont-miss-the-opportunity-be-an-early-investor/"
                    title="Businessheadline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Businessheadline} alt="business-headline" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://www.todayinbusiness.com/article/695960788-fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="Todayinbusiness"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Todayinbusiness} alt="todayinbusiness" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://finance.yahoo.com/news/fuzuki-inu-shiba-kanji-rising-160000678.html"
                    title="Yahoo Finance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Yahoo} alt="Yahoo Finance" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://gdc.einnews.com/pr_news/695960788/fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="Gdc Einnews"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Gdc} alt="gdc-einnews" />
                    </div>
                  </a>
                  <a
                    className="testimonial-box"
                    href="https://ems.einnews.com/pr_news/695960788/fuzuki-inu-shiba-kanji-the-rising-star-of-the-blockchain"
                    title="Ems Einnews"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="brand-media">
                      <img src={Ems} alt="ems-einnews" />
                    </div>
                  </a>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
