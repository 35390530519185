import React, { useState, useEffect } from 'react';
import {  otpUpdateOtp, otpDetailClear, loginUserSuccess, setUserDTO, setUserToken, otpUpdateId } from '../../Redux/actions/authAction';
import otppass from '../../Pages/images/IconOTP.png';
import { services } from '../../utils/services';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { changeModal, hideModal } from '../../Redux/actions/modalActions';
import { MODAL_TYPE, NOTIFICATION_TYPE } from '../../utils/constant';
import { showNotification } from '../../Redux/actions/notificationAction';
import { showLoader } from '../../Redux/actions/homeAction';

// confirm({
//     title: 'Do you want to delete current ministry? If you delete ministry then all the licenses will be deleted.',
//     // icon: <ExclamationCircleOutlined />,
//     // content: '',
//     onOk: async () => {
//         const response = await LicenseFormServices.deleteMinistry(ministryId).catch(e => {
//             return false;
//         });
//         const responseData = get(response, 'data', {});
//         if (responseData && responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
//             listData();
//         }
//         else {
//             notification['error']({
//                 message: <strong>{responseData.message}</strong>})
//         }
//     },
//     onCancel() {
//     },
// });

const Otp = () => {
    // const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const [disabledBtn, setDisabledBtn] = useState(true)

    const otpDetail = useSelector(state => get(state, 'auth.otpDetail', ""));
    const dispatch = useDispatch();

    const onSubmit = async () => {
        const param = {
            "userAuthId": otpDetail?.userAuthId,
            "otp": otpDetail?.otp
        };
        setDisabledBtn(true)
        if (otpDetail?.otp && otpDetail?.otp?.length === 6) {
            dispatch(showLoader(true))
            let response = "";
            if (otpDetail?.otpType === MODAL_TYPE.REGISTRATION) {
                response = await services.registerOTPVerification(param);

            } else if (otpDetail?.otpType === MODAL_TYPE.LOGIN) {
                response = await services.loginOTPVerification(param);
            }
            if (response?.status) {
                if (otpDetail?.otpType === MODAL_TYPE.REGISTRATION) {
                    dispatch(changeModal(MODAL_TYPE.LOGIN))
                } else if (otpDetail?.otpType === MODAL_TYPE.LOGIN) {
                    dispatch(loginUserSuccess(get(response, 'result')))
                    setUserDTO(response);
                    setUserToken(response);
                    dispatch(hideModal())
                }
                dispatch(otpDetailClear())
                dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, get(response, 'message')));
                setDisabledBtn(false)
                dispatch(showLoader(false))
            } else {
                // dispatch(otpDetailClear())
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, get(response, 'message', "Something went wrong.")));
                setDisabledBtn(false)
                dispatch(showLoader(false))
            }
        }
    }

    const submitCashout = async () => {
        const param = {
            "userAuthId": otpDetail?.userAuthId,
            "otp": otpDetail?.otp
        };
        setDisabledBtn(true)
        if (otpDetail?.otp && otpDetail?.otp?.length === 6) {
            dispatch(showLoader(true))
            const response = await services.cashoutOTPVerification(param);
            if (response?.status) {
                dispatch(hideModal())
                dispatch(otpDetailClear())
                dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, get(response, 'message')));
                setDisabledBtn(false)
                dispatch(showLoader(false))
            }
            else {
                dispatch(otpDetailClear())
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, get(response, 'message', "Something went wrong.")));
                setDisabledBtn(false)
                dispatch(showLoader(false))
                dispatch(hideModal())
            }
        }
    }

    const resendOtp = async () => {
        dispatch(showLoader(true))
        try {

            const response = await services.resendOTP(otpDetail?.userAuthId);
            if (!isEmpty(response)) {
                if (get(response, 'status')) {
                    dispatch(otpUpdateId(get(response, 'result')))
                    setError(get(response, 'message'));
                    // dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, get(response, 'message')));
                }
                dispatch(showLoader(false))
            }
        } catch (err) {
            dispatch(showLoader(false))
        }
    }
    useEffect(() => {
        return () => {
            dispatch(otpDetailClear())
        };
    }, []);

    return (
        <div className="modal-detail" >
            <div className="info-head">
                <h2>OTP Verification</h2>
            </div>
            <div className="input-group field-input">
                <img className="img-icon" src={otppass} alt="otp" />
                <input
                    type="text"
                    maxLength="6"
                    placeholder="OTP"
                    className="token-qty"
                    onChange={e => dispatch(otpUpdateOtp(e.target.value))}
                    value={otpDetail?.otp}
                />
            </div>
            <button onClick={() => resendOtp()} className="btn text-light resend-btn">resend</button>
            <button
                onClick={() => {
                    if (otpDetail?.otpType === MODAL_TYPE.REGISTRATION || otpDetail?.otpType === MODAL_TYPE.LOGIN) {
                        onSubmit()
                    } else if (otpDetail?.otpType === MODAL_TYPE.CASHOUT) {
                        submitCashout()
                    }
                }
                }
                className="entergame-btn"
            // disabled={disabledBtn}
            >Verify</button>
            {
                error &&
                <span>
                    {error}
                </span>

            }
        </div>
    )
}

export default Otp;
