export const loginUserApi = {
    method: "POST",
    url: "user/login"
}

export const registerUserApi = {
  method: "POST",
  url: "user/register"
}

export const getGamesApi = {
  method: "GET",
  url: "game/getall"
}

export const getUserDetailApi = {
  method: "GET",
}

export const getUserResultsApi = {
  method: "GET",
}

export const getBlogsApi = {
  method: "GET",
  url: "blog/all"
}

export const getBlogBySlugApi = {
  method: "GET"
}

export const doStartPlayApi = {
  method: "POST",
  url: "battle/start"
}

export const  registerOTPVerificationApi = {
  method: "POST",
  url: "user/register/otp/validate"
}
export const  loginOTPVerificationApi = {
  method: "POST",
  url: "user/login/otp/validate"
}
export const  resendOTPApi = {
  method: "GET",
}

export const updateBlogApi = {
  method: "PUT",
  url: "blog/update"
}

export const uploadProfileImageApi = {
  method: "POST",
  url: "user/updateprofileimage"
}

export const updateUsernameApi = {
  method: "POST",
  url: "user/update"
}
export const checkUsernameApi = {
  method: "POST",
  url: "user/check"
}

export const doCashoutApi = {
  method: "POST",
  url: "cashout/fund-transfer"
}
export const  cashoutOTPVerificationApi = {
  method: "POST",
  url: "cashout/otp/validate"
}