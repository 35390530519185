import { React, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import name from '../../Pages/images/name.png';
import email from '../../Pages/images/email.png';
import password from '../../Pages/images/password.png';
import { changeModal } from '../../Redux/actions/modalActions';
import { MODAL_TYPE } from '../../utils/constant';
import { doRegisterUser } from '../../Redux/actions/authAction';
import 'react-phone-number-input/style.css'

const defaultFormData = {
    userName: "",
    password: "",
    email: ""
}

const RegisterModal = () => {
    const [registrationForm, setRegistrationForm] = useState(defaultFormData);
    const [error, setError] = useState({});

    const dispatch = useDispatch();

    const onChange = (e, name) => {
        const _value = get(e, 'target.value');
        setRegistrationForm(s => {
            return { ...s, [name]: _value }
        })
    }
    const validateForm = () => {
        const _error = {};
        if (!isEmpty(registrationForm)) {
            if (!get(registrationForm, "userName")) {
                _error["userName"] = "This field is required";
            }
            if (!get(registrationForm, "password")) {
                _error["password"] = "This field is required";
            }
            if (!get(registrationForm, "email")) {
                _error["email"] = "This field is required";
            }
        }
        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const onSubmitHandler = () => {
        if (validateForm()) {
            dispatch(doRegisterUser({ ...registrationForm }));
        }
    }

    return (
        <div className="modal-detail" >
            <div className="info-head">
                <h2>Create an account</h2>
            </div>
            <div className="input-items">
                <div className="input-group field-input">
                    <img className="img-icon" src={name} alt="name" />
                    <input
                        type="text"
                        placeholder="Username"
                        className="token-qty"
                        value={registrationForm.userName}
                        onChange={e => onChange(e, 'userName')}
                    />
                    {
                        get(error, 'userName') &&
                        <span className='requiredMsg'>
                            {get(error, 'userName')}
                        </span>

                    }
                </div>
                <div className="input-group field-input">
                    <img className="img-icon" src={email} alt="email" />
                    <input
                        type="email"
                        placeholder="Email"
                        className="token-qty"
                        value={registrationForm.email}
                        onChange={e => onChange(e, 'email')}
                    />
                    {
                        get(error, 'email') &&
                        <span className='requiredMsg'>
                            {get(error, 'email')}
                        </span>

                    }
                </div>
                <div className="input-group field-input">
                    <img className="img-icon" src={password} alt="password" />
                    <input
                        type="password"
                        placeholder="Password"
                        className="token-qty"
                        value={registrationForm.password}
                        onChange={e => onChange(e, 'password')}
                    />
                    {
                        get(error, 'password') &&
                        <span className='requiredMsg'>
                            {get(error, 'password')}
                        </span>

                    }
                </div>

            </div>
            <button className="entergame-btn" onClick={() => onSubmitHandler()}>Register</button>
            <label
                className="info-text">
                Already have an account,
                <button
                    className='btn btn-link'
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(changeModal(MODAL_TYPE.LOGIN))
                    }}>Login</button></label>
        </div>
    )
}

export default RegisterModal
