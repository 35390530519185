import axios from 'axios';
import {getToken, doLogout} from '../Redux/actions/authAction';
import { BASE_URL } from './GlobalConfig';
import { get } from 'lodash';
export  const axiosServices =axios.create({baseURL: BASE_URL});

export const httpRequest = ({...options}) => {
    const token = getToken();
    if(token) {
        axiosServices.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    const onSuccess = (response) => response;
    const onError= error => {
        if (get(error, 'response.status', "") === 401) {
            localStorage.clear();
            doLogout();
          }
    }

    return axiosServices(options).then(onSuccess).catch(onError);
}
