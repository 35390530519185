import React, { useEffect, useState } from "react";
import {
  defaultConfig,
  createWeb3Modal,
  useWeb3ModalAccount,
  useWeb3Modal,
} from "@web3modal/ethers5/react";
import { hideModal } from "../../Redux/actions/modalActions";
import { useDispatch } from "react-redux";
import { Web3Modal } from "@web3modal/ethers5";
import { getToken } from "../../Redux/actions/authAction";
import { ethers } from "ethers";
import { signForProfile } from "../../utils/web3Function";
import { doLogin, doLogout } from "../../Redux/actions/authAction";
import { jwtDecode } from "jwt-decode";
import { useDisconnect } from "@web3modal/ethers5/react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { userLogin } from "../../Redux/actions/authAction";

function ConnectWalletModal({ showModal }) {
  const isLogin = useSelector((state) => get(state, "auth.userLogin", false));
  const [balance, setBalance] = useState(null);
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const projectId = "aeaedf8faef3876c08ca5a03cbc3898a";
  const mainnet = {
    chainId: 80002,
    name: "Amoy",
    currency: "Matic",
    explorerUrl: "https://amoy.polygonscan.com/",
    rpcUrl: "https://rpc.ankr.com/polygon_amoy",
  };

  const metadata = {
    name: "My Website",
    description: "My Website description",
    url: "https://mywebsite.com", // origin must match your domain & subdomain
    icons: ["https://avatars.mywebsite.com/"],
  };

  const ethersConfig = defaultConfig({
    metadata,
  });

  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true,
  });
  const { open } = useWeb3Modal();
  const onHide = () => {
    dispatch(hideModal());
  };

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log(address);

  const callSignFunction = async () => {
    const token = getToken();
    console.log(token);
    if (address) {
      if (token) {
        //decode the token here and check that if the token conatain the same address or not
        const decoded = jwtDecode(token?.toString());
        console.log(decoded);

        if (decoded.walletAddress !== address) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const { hashedMessage, signature } = await signForProfile(
            provider,
            address
          );
          console.log(signature);
          if (signature !== "User rejected the request.") {
            await dispatch(doLogin({ signature }));
            const token = getToken();
            console.log("line-77", token);
            setToken(token);
          } else {
            console.log("line-85");
            disconnect();
          }
        }
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const { hashedMessage, signature } = await signForProfile(
          provider,
          address
        );
        if (signature !== "User rejected the request.") {
          await dispatch(doLogin({ signature }));
          const token = getToken();
          console.log("line-92", token);
          setToken(token);
        } else {
          console.log("line-101");
          disconnect();
        }
      }
    }
  };

  const fetchBalance = async () => {
    if (address) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(address);
      setBalance(ethers.utils.formatEther(balance));
    }
  };

  //   useEffect(() => {
  //     const handleButtonClick = () => {
  //       onHide();
  //     };

  //     const button = document.querySelector("w3m-button");
  //     if (button) {
  //       button.addEventListener("click", handleButtonClick);
  //     }

  //     return () => {
  //       if (button) {
  //         button.removeEventListener("click", handleButtonClick);
  //       }
  //     };
  //   }, []);

  useEffect(() => {
    // const token = getToken();
    // setToken(token);
    if (!showModal) {
      onHide();
      open();
    }
  }, []);

  useEffect(() => {
    callSignFunction();
    if (address) {
      fetchBalance();
    }
    if (!address && isLogin) {
      dispatch(doLogout());
    }
  }, [address]);

  useEffect(() => {
    const newToken = getToken();
    if (newToken) {
      dispatch(userLogin(true));
    }
  }, []);
  return (
    <div>
      {showModal ? (
        <>
          {" "}
          {/* <w3m-button /> */}
          {!isLogin ? (
            <button
              style={{
                borderRadius: "23px",
                height: "45px",
                backgroundColor: "red",
                color: "white",
                fontWeight: "bold",
                width: "150px",
              }}
              onClick={() => open()}
            >
              Connect Wallet
            </button>
          ) : (
            <>
              {/* <w3m-account-button /> */}
              <button
                disabled={true}
                style={{
                  borderRadius: "23px",
                  height: "40px",
                  backgroundColor: "transaparent",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {balance}
              </button>
              <button
                style={{
                  borderRadius: "23px",
                  height: "40px",
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={() => open()}
              >
                Connected
              </button>
            </>
          )}
        </>
      ) : null}

      {/* <w3m-connect-button /> */}
    </div>
  );
}

export default ConnectWalletModal;
