import { combineReducers } from "redux";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER_CALL,
  OTP_UPDATE_ID,
  OTP_UPDATE_OTP,
  OTP_DETAIL_CLEAR,
  OTP_TYPE,
  USER_LOGIN,
  USER_LOGINOUT,
} from "../actions/authAction";

const initialState = {
  loading: false,
  user: {},
  error: "",
};
const initialOTPState = {
  userAuthId: 0,
  otp: "",
  otpType: "",
};

const userDetail = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case LOGOUT_USER_CALL:
      return { ...initialState };
    default:
      return state;
  }
};

const otpDetail = (state = initialOTPState, action) => {
  switch (action.type) {
    case OTP_UPDATE_ID:
      return {
        ...state,
        userAuthId: action.payload,
      };
    case OTP_UPDATE_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case OTP_DETAIL_CLEAR:
      return {
        ...state,
        userAuthId: 0,
        otp: "",
        otpType: "",
      };
    case OTP_TYPE:
      return {
        ...state,
        otpType: action.payload,
      };
    default:
      return state;
  }
};

const userLogin = (state = false, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.payload;
    case USER_LOGINOUT:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  userDetail,
  otpDetail,
  userLogin,
});
