import React from "react";
import { useDispatch } from "react-redux";
import { isEmpty, get } from "lodash";
// import matchking from '../../Pages/images/matchking.png';
// import Modal from 'react-bootstrap/Modal';
import { MODAL_TYPE, NOTIFICATION_TYPE } from "../../utils/constant";
import { showActiveGame } from "../../Redux/actions/modalActions";
import { CDN_IMAGE_URL } from "../../utils/GlobalConfig";
import { showNotification } from "../../Redux/actions/notificationAction";
// import PropTypes from 'prop-types';

const Card = ({ game }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="gamename col-md-3 col-6"
      onClick={() => {
        if (!isEmpty(game.tiers)) {
          dispatch(showActiveGame(MODAL_TYPE.GAME_MODEL, game));
        } else {
          dispatch(
            showNotification(NOTIFICATION_TYPE.SUCCESS, "Something Went Wrong.")
          );
        }
      }}
    >
      <div className="img-container">
        <img
          src={`${CDN_IMAGE_URL}${get(game, "imageURL", "")}`}
          alt="game-name"
        />
      </div>
      <h4>{get(game, "name", "")}</h4>
      <span>{get(game, "category", "")}</span>
    </div>
  );
};

export default Card;
