import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Registration from "./RegisterModal";
import GameModal from "./Modalgame";
import Cashout from "./cashout";
import { hideModal, showModal } from "../../Redux/actions/modalActions";
import UpdateUsername from "./UpdateUsername";
import Modal from "react-bootstrap/Modal";
import { MODAL_TYPE } from "../../utils/constant";
import ConnectWalletModal from "./ConnectWalletModal";
import Otp from "./Otp";

function ModalContainer(props) {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalTypes.type);

  useEffect(() => {
    dispatch(showModal(MODAL_TYPE.NONE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModalUI = () => {
    switch (modalType) {
      // case MODAL_TYPE.LOGIN:
      //     return <Login />
      case MODAL_TYPE.LOGIN:
        return <ConnectWalletModal showModal={false} />;
      case MODAL_TYPE.REGISTRATION:
        return <Registration />;
      case MODAL_TYPE.GAME_MODEL:
        return <GameModal />;
      case MODAL_TYPE.CASHOUT:
        return <Cashout />;
      case MODAL_TYPE.OTP:
        return <Otp />;
      case MODAL_TYPE.UPDATEUSERNAME:
        return <UpdateUsername />;
      default:
        return null;
    }
  };
  const onHide = () => {
    dispatch(hideModal());
  };
  return (
    <>
      <Modal
        backdrop="static"
        className="center-modal"
        backdropClassName="hb-modal-background"
        show={modalType !== MODAL_TYPE.NONE}
      >
        <Modal.Header closeButton onHide={onHide} />
        <Modal.Body>{modalType !== MODAL_TYPE.NONE && getModalUI()}</Modal.Body>
      </Modal>
    </>
  );
}

export default ModalContainer;
