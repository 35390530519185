export default [
    {
      "gameID": 1,
      "tiers": [],
      "name": "Cops Clash",
      "category": "Racing",
      "imageURL": "images/Cops Clash.jpg",
      "videoURL": "",
      "webGLUrl": "webgl/CopsClash/index.html",
      "gameOfWeek": true,
      "viewOrder": 0
    },
    {
      "gameID": 4,
      "tiers": [],
      "name": "Hurdle Hop",
      "category": "Casual",
      "imageURL": "images/Hurdle Hop.jpg",
      "videoURL": "",
      "webGLUrl": "webgl/HurdleHop/index.html",
      "gameOfWeek": true,
      "viewOrder": 0
    },
    {
      "gameID": 8,
      "tiers": [],
      "name": "Tunnel Trouble",
      "category": "Racing",
      "imageURL": "images/Tunnel Trouble.jpg",
      "videoURL": "",
      "webGLUrl": "webgl/TunnelTrouble/index.html",
      "gameOfWeek": true,
      "viewOrder": 0
    },
    {
      "gameID": 10,
      "tiers": [],
      "name": "Counting Snakes",
      "category": "Casual",
      "imageURL": "images/Counting Snakes.jpg",
      "videoURL": "",
      "webGLUrl": "webgl/CountingSnakes/index.html",
      "gameOfWeek": true,
      "viewOrder": 0
    }
  ]